import { oneLine } from 'common-tags';
import { IRule, IRulesMediaCard } from 'interface';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PopupVideo } from '../molecules';
const baseUrl = 'https://storage.googleapis.com/engage-transcoded-videos/';
const baseUrlUploaded = 'https://storage.googleapis.com/engage-uploaded-videos/';

type IProps = {
  ready: boolean;
  uuid: string;
  rules: IRulesMediaCard[];
};

const FormatMediaCard = ({ rules, uuid, ready }: IProps) => {
  const intl = useIntl();

  const [previewVideo, setPreviewVideo] = useState<boolean>(false);
  const [themeMode, setThemeMode] = React.useState<string | null>(localStorage.getItem('mode'));
  const [src, setSrc] = useState<string>(themeMode === 'darkMode' ? '/images/media-processing-dark.svg' : '/images/media-processing.svg');
  const [hasError, setHasError] = useState<boolean>(false); // Flag pentru eroare


  React.useEffect(() => {
    setThemeMode(localStorage.getItem('mode'));
  }, []);

  const handleClick = () => {
    if(!hasError)
    setPreviewVideo(true);
  };

  const handleClose = () => {
    setPreviewVideo(false);
  };

  useEffect(() => {
    if (ready && !hasError) { 
      setSrc(`${baseUrl}${uuid}_thumb.jpg`);
    }
  }, [ready, uuid, hasError]);

  const handleImageError = () => {
    if (!hasError) { 
      setSrc(themeMode === 'darkMode' ? '/images/media-processing-dark.svg' : '/images/media-processing.svg');
      setHasError(true);
    }
  };

  return (
    <div className={oneLine`w-full bg-white dark:bg-dark-200 p-2 rounded space-y-2 relative`}>
      <div aria-describedby={uuid} onClick={handleClick} className={`relative ${previewVideo ? 'active' : ''}`}>
        <div className="relative w-full h-56 flex items-center justify-center overflow-hidden bg-gray-100 dark:bg-dark-100 rounded-3xl cursor-pointer">
          <img 
            src={src} 
            className="h-full w-auto object-contain object-center"
            onError={handleImageError}
            alt="Media thumbnail"
          />
        </div>
      </div>

      {previewVideo && (
        <PopupVideo
          closePopup={handleClose}
          key="1"
          preview={`${baseUrl}${uuid}_preview.mp4`}
        />
      )}
    </div>
  );
};
export default FormatMediaCard;
