import React, { useEffect } from 'react';
import { Icon } from '@iconify/react-with-api';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { PortalNaked } from 'components/common/atoms/Portal/PortalNaked';
import { AddtoCart, Button, MapTooltipColor } from 'components/common/atoms';
import useLocationModal from '../../../../hooks/useLocationModal';
import LocationModalType from '../../../../interface/LocationModal';
import { JWT } from '@api/users';
import { Carousel } from 'react-responsive-carousel';
import { FormattedMessage } from 'react-intl';
import { oneLine } from 'common-tags';
import { useVenues } from '@utils/venues';
import AdminLocationButtons from 'components/common/molecules/LocationModal/AdminButtons';
import HourlyAudience from 'components/common/molecules/LocationModal/HourlyAudience';
import ScreenRequest from 'components/common/molecules/LocationModal/ScreenRequests';
import { IPublishType } from '../../../../context/publish/publish.reducer';
import { CAMPAIGNS } from '@api/index';
import { PublishContext } from '../../../../context/publish/publish.provider';
import { useApp } from 'components/app';
import { ILocationStatus } from '../../../../interface/ILocation';
import { Marker, RotationControl, ZoomControl } from 'react-mapbox-gl';
import MapPin from 'components/common/molecules/MapPin';
import SmallMap from 'components/common/molecules/LocationModal/SmallMap';
import { useMondayContext } from '../../../../context/monday/monday.reducer';

type MapToolTipPortalProps = {
  focusedLocation: string;
  onCloseTooltip: () => void;
  isManualBulk?: boolean;
  showMap?: boolean;
  changeBook?: () => void;
  openPreview: (photosParam: string[], locationName: string | undefined) => void;
  onPreviewOpen: (photosParam: string[]) => void;
  onRemove?: (location: any) => {};
  onAddManual?: (location: any) => {};
};

const lineClasses = oneLine`
  flex flex-col
  text-xs
  text-body
  dark:text-dark-300
  font-medium
  `;

const LocationModal = ({
  focusedLocation,
  isManualBulk,
  onCloseTooltip,
  showMap,
  changeBook,
  openPreview,
  onRemove,
  onAddManual,
}: MapToolTipPortalProps) => {
  const [photos, setLocationPhotos] = React.useState<string[]>([]);
  const { publishState, dispatch } = React.useContext(PublishContext);

  const [location, setLocation] = React.useState<LocationModalType | null>(null);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const { query } = useLocationModal({ location: focusedLocation });
  const [thumbs, setThumbnails] = React.useState<string[]>([]);
  const [bookLoading, setBookLoading] = React.useState<boolean>(false);
  const [isBooked, setIsBooked] = React.useState<boolean>(false);
  const [agoTime, setAgoTime] = React.useState<string>('');
  const { notify } = useApp();
  const { state, dispatch: bulkDispatch } = useMondayContext();

  const modeStorage = localStorage.getItem('mode');

  useEffect(() => {
    if (location) {
      if (isManualBulk) {
        let foundLocation = state.locations || [];
        let l = foundLocation.filter(el => {
          return el.id === location?.uuid;
        });

        if (l.length > 0) {
          setIsBooked(true);
        } else {
          setIsBooked(false);
        }
      } else {
        if (publishState.locations && location) {
          const uuid = location.uuid;
          const checkBook = publishState.locations.filter(el => el.location.uuid === uuid).length > 0;
          setIsBooked(checkBook || false);
        }
      }
    }
  }, [publishState.locations, state.locations, location]);
  let user = JWT.getJwtUser();

  useEffect(() => {
    if (user.role === 'admin') setIsAdmin(true);
    window.kommunicate?.displayKommunicateWidget?.(false);

    if (query.status === 'success') {
      setLocation(query.data[0]);

      if (publishState.locations) {
        const uuid = query.data[0].uuid;
        const lastRequestTime = query.data[0].stockData.lastRequestTime;
        if (lastRequestTime) {
          const time = new Date(lastRequestTime);
          const now = new Date();
          const diff = now.getTime() - time.getTime();
          const minutes = Math.floor(diff / 60000);
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);
          if (days > 0) {
            setAgoTime(`${days} days ago`);
          } else if (hours > 0) {
            setAgoTime(`${hours} hours ago`);
          } else {
            if (minutes < 0) setAgoTime('Just now');
            else setAgoTime(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}  ago`);
          }
        }

        const checkBook = publishState.locations.filter(el => el.location.uuid === uuid).length > 0;
        setIsBooked(checkBook || false);
      }

      let photosLoc = [];
      let thumbnailsLoc = [];
      if (query.data[0].locationImages && query.data[0].locationImages > 0) {
        for (let i = 0; i < query.data[0].locationImages; i++) {
          photosLoc.push('https://i.seeblindspot.com/' + query.data[0].uuid + '/0' + i);
          thumbnailsLoc.push('https://i.seeblindspot.com/thumb/' + query.data[0].uuid + '/0' + i);
        }
      } else {
        if (query.data[0].networkImages === 0) {
          photosLoc.push(`https://blindspot-location-photos.s3.us-east-2.amazonaws.com/0000_screenPlaceholder.jpg`);
          thumbnailsLoc.push(
            `https://blindspot-location-photos-small.s3.us-east-2.amazonaws.com/thumb/0000_screenPlaceholder.jpg`
          );
        } else {
          const netId = query.data[0].networkUuid;
          if (query.data[0].networkImages)
            for (let i = 0; i < query.data[0].networkImages; i++) {
              if (netId) {
                photosLoc.push(`https://i.seeblindspot.com/${netId}/0${i}`);
                thumbnailsLoc.push(`https://i.seeblindspot.com/thumb/${netId}/0${i}`);
              }
            }
        }
      }
      if (photosLoc.length > 1) setLocationPhotos([...photosLoc, photosLoc[0]]);
      else setLocationPhotos([...photosLoc]);

      setThumbnails([...thumbnailsLoc]);
    }
    return () => {
      setLocation(null);
      setLocationPhotos([]);
      setThumbnails([]);
      setIsAdmin(false);
      setIsBooked(false);
      setAgoTime('');
      window.kommunicate?.displayKommunicateWidget?.(true);
    };
  }, [query.status]);

  const addLocation = async () => {
    setBookLoading(true);
    if (isManualBulk) {
      setBookLoading(true);
      // @ts-ignore
      let foundLocation = state.locations.filter(el => el.id === location?.uuid || el.uuid === location?.uuid).length;
      if (foundLocation && foundLocation > 0) {
        if (onRemove) {
          onRemove(location?.uuid);
          setIsBooked(false);
        }
      } else {
        if (onAddManual) {
          if (location) {
            const response = await CAMPAIGNS.getLocationById(location.uuid);
            if (response.locations && response.status) {
              response.locations.stock = location.stock;

              console.log('location', {
                ...response.locations,
                id: location.uuid,
                // @ts-ignore
                locationImages: focusedLocation.locationImages,
                // @ts-ignore
                networkImages: focusedLocation.networkImages,
              });
              onAddManual({
                ...response.locations,
                id: location.uuid,
                // @ts-ignore
                locationImages: focusedLocation.locationImages,
                // @ts-ignore
                networkImages: focusedLocation.networkImages,
              });
              setIsBooked(true);
              notify('Screen was ADDED', 'success', 'top-right');
            } else {
              notify('Oops, something went wrong!', 'error', 'top-right');
              setIsBooked(false);
            }
          }
        }
      }
      setBookLoading(false);
      changeBook?.();
      onCloseTooltip();
    } else {
      let foundLocation = publishState.locations?.filter(el => el.location.uuid === location?.uuid).length;

      if (foundLocation && foundLocation > 0) {
        let deleteList = [location?.uuid];
        dispatch({
          type: IPublishType.removeLocation,
          payload: {
            mapLocations: deleteList,
          },
        });
        notify('Screen was REMOVED', 'success', 'top-right');
      } else {
        if (location) {
          const response = await CAMPAIGNS.getLocationById(location.uuid);

          if (response.locations && response.status) {
            // response.locations.stock = location.stock;
            const pphUsed = response.locations.frequencies?.[0]?.seconds || response.locations.campaignFrequency;
            dispatch({
              type: IPublishType.addLocation,
              payload: {
                location: {
                  ...response.locations,
                  locationImages: location.locationImages,
                  networkImages: location.networkImages,
                },
                pph: pphUsed,
                totalLocations: publishState.locations?.length ?? 0,
              },
            });

            notify('Screen was ADDED', 'success', 'top-right');
          } else {
            notify('Oops, something went wrong!', 'error', 'top-right');
          }
        }
      }
      changeBook?.();
      setBookLoading(false);
    }
  };

  return (
    <PortalNaked>
      <div
        className="fixed inset-0 dark:text-white bg-black/50 z-[999] flex justify-end items-end"
        onClick={onCloseTooltip}
      >
        <div
          className="bg-whiteish dark:bg-dark-200 p-4 pb-0 mt-0 h-screen w-full md:w-[390px] animate-slide-in relative  space-y-8"
          onClick={e => e.stopPropagation()}
        >
          {query.status === 'loading' ? (
            <div className="flex items-center h-full">
              {modeStorage === 'darkMode' ? (
                <div className="loader-container-gif">
                  <img src="/images/logo-loader.gif" alt="this slowpoke moves" width="40%" />
                </div>
              ) : (
                <div className="flex items-center flex-col">
                  <img src="/images/logo-blindspot.svg" alt="this slowpoke moves" width="40%" />
                  <img src="/images/loading.svg" alt="this slowpoke moves" width="40%" />
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="h-full pb-20 overflow-y-scroll px-2 pt-36 lg:pt-0 ">
                <div className="sticky top-0 bg-whiteish dark:bg-dark-200 w-full px-0 py-2 z-[99]">
                  <div className="flex items-start justify-between ">
                    <div className="w-10/12">
                      <h2 className="text-lg font-semibold mb-1 ">{location?.n || 'n/a'}</h2>
                    </div>

                    <button onClick={onCloseTooltip} className="text-gray-400  rounded-full w-10 h-10 ">
                      <Icon icon="ant-design:close-circle-outlined" className="w-10 h-10 " />
                    </button>
                  </div>
                  {agoTime?.length > 0 && (
                    <>
                      <div className="text-sm text-gray-400 mb-2">
                        Last ad served <span className="text-orange-default ">{agoTime}</span>
                      </div>
                    </>
                  )}
                  {location && (
                    <MapTooltipColor
                      isListItem={false}
                      stock={{
                        value: location?.stockData?.stock?.value || 0,
                        color: location?.stockData?.stock?.color || 'red',
                      }}
                    />
                  )}
                </div>
                <div className="w-full pt-4">
                  <div className={`h-40 relative moveTopArrow`}>
                    <div
                      onClick={() => {
                        //remove last image from array

                        if (photos[photos.length - 1] === photos[0] && photos.length > 1) photos.pop();
                        openPreview(photos, location?.n);
                      }}
                      className="absolute right-0 flex flex-row justify-end p-2 overflow-hidden dark:text-white text-black bg-white dark:bg-dark-200 rounded-r-none cursor-pointer preview-image-handler align-items bottom-4 rounded-2xl z-[99]"
                    >
                      <div className="ml-1 text-md mr-2 text-sm font-semibold whitespace-nowrap">
                        <FormattedMessage id="Text.PreviewImages" />
                      </div>
                      <div>
                        <Icon className="" height="24px" width="24px" icon="akar-icons:eye" />
                      </div>
                    </div>

                    {(location?.country === 'Romania' && location?.onboardingStatus !== ILocationStatus.live) ||
                      (location?.stockData?.stock?.color === 'soldout' && (
                        <div
                          className={oneLine`soldout pointer-events-none flex flex-row items-center justify-center w-full h-[140px] absolute top-0 left-0  `}
                        >
                          <img src="/images/sold-out.svg" loading="lazy" className="object-contain w-9/12" />
                        </div>
                      ))}

                    {photos.length === 1 ? (
                      <img src={photos[0]} className="h-full carousel-photo" />
                    ) : (
                      <Carousel
                        infiniteLoop
                        dynamicHeight={false}
                        showStatus={false}
                        showArrows={true}
                        selectedItem={1}
                        showThumbs={false}
                      >
                        {photos.map((el: any, index: any) => (
                          <div key={index}>
                            <img src={el} className="rounded-xl" />
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </div>
                </div>
                <div className="mb-6 pt-4">
                  <div className="text-sm text-gray-400">{location?.address || 'Address Not Available'}</div>
                  <a
                    target="_blank"
                    className="text-primary-500 text-sm underline flex items-center space-x-2"
                    href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${location?.lt},${location?.lg}&heading=-45&pitch=0&fov=80`}
                    rel="noreferrer"
                  >
                    <span>Show location on STREET VIEW</span>
                    <Icon className="" height="1rem" width="1rem" icon="cuida:open-in-new-tab-outline" />
                  </a>
                  {location && showMap && (
                    <div className="py-6">
                      <SmallMap name={location?.n || 'n/a'} lat={location?.lt} lng={location?.lg} />
                    </div>
                  )}

                  <div className="space-y-2 pt-2">
                    <a
                      href={`https://maps.google.com/?q=${location?.lt},${location?.lg}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="text-primary-500 text-sm underline flex items-center space-x-2">
                        <span>Show location on Google Maps</span>
                        <Icon icon="mdi:google-maps" height="1rem" width="1rem" />
                      </div>
                    </a>
                  </div>
                </div>
                {user.id !== '' && (
                  <div className="bg-dark-300/20 px-4 py-2  p-4 rounded-lg mb-6">
                    <div className="text-sm mb-2">Price Reference</div>
                    <div className="flex items-center justify-start space-x-4">
                      <div className="text-2xl font-bold">{location?.averagePrice || '0'}</div>
                      <div className="text-sm text-gray-400 ">
                        avg. price per hour
                        <br />
                        ad play
                      </div>
                    </div>
                  </div>
                )}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-2">Ad Specs</h3>
                  <div className="text-sm text-gray-400 mb-4">The physical characteristics of this board.</div>
                  <div className="bg-dark-300/20 px-4 py-2  p-4 rounded-lg mb-6">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-4">
                      <div className="mb-4">
                        <div className="text-base text-gray-400 mb-1">Accepted Formats</div>
                        <div className="text-sm">
                          {location?.contentType.includes('video') || location?.contentType.includes('static')
                            ? 'Static: PNG, JPG'
                            : ' '}
                          <br />
                          {location?.contentType.includes('video') ? 'Video: MP4' : ''}
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="text-sm text-gray-400 mb-1">
                          <FormattedMessage id="Text.Screens" />
                        </div>
                        <div className="text-sm">{location?.circuit}</div>
                      </div>
                      <div className="mb-4">
                        <div className="text-sm text-gray-400 mb-1">
                          <FormattedMessage id="Text.Type" />
                        </div>
                        <div>{location?.type}</div>
                      </div>
                      <div className="mb-4">
                        <div className="text-sm text-gray-400 mb-1">SCREEN RESOLUTION</div>
                        <div className="text-sm">
                          {location?.horRez} x {location?.vertRez} px
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="text-sm text-gray-400 mb-1">
                          {' '}
                          <FormattedMessage id="Text.Bookable" />
                        </div>
                        <div className="text-sm">{location?.acceptedSchedules}</div>
                      </div>
                      <div className="mb-4">
                        <div className="text-sm text-gray-400 mb-1">Venue (Location) Type</div>
                        <div className="text-sm">
                          {location?.venyueTypeId
                            ? useVenues().getVenuById(Number(location?.venyueTypeId))?.title
                            : 'Venue type - Not Available'}
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="text-sm text-gray-400 mb-1">Creative Duration</div>
                        <div className="text-sm">
                          {location?.acceptedDurations.map((el, index) => {
                            return index === location.acceptedDurations.length - 1 ? el + 's' : el + 's, ';
                          })}
                        </div>
                      </div>
                      {location?.minBookingHours && (
                        <div className="mb-4">
                          <div className="text-sm text-gray-400 mb-1">Min plays</div>
                          <div className="text-sm">{location.minBookingHours}</div>
                        </div>
                      )}
                    </div>

                    {location?.tlp &&
                      location.tlp.length > 0 &&
                      location.tlp.filter((el: any) => el.properties.uuid !== '59ba9582-a8a6-422c-a880-27fa418ca227')
                        .length > 0 && <p className={`${lineClasses} pt-5`}>This location has Local Advertising Tax</p>}

                    {location?.otherRestriction && (
                      <div className="mb-4">
                        <div className="text-sm  mb-1 ">Restrictions</div>
                        <div className="text-orange-default text-sm">{location.otherRestriction}</div>
                      </div>
                    )}
                  </div>
                  {location && <HourlyAudience location={location} />}
                  {location && <ScreenRequest location={location} />}
                  <div>
                    <a
                      rel="noopener noreferrer"
                      href={`/location/${location?.uuid}`}
                      target="_blank"
                      className="w-full"
                    >
                      <Button
                        className="mt-4 whitespace-nowrap"
                        data-cy="popDetails"
                        fullWidth
                        color="primary"
                        fill="outlinedark"
                        buttonSize="sm"
                      >
                        View location page
                      </Button>
                    </a>
                  </div>
                  {location && (
                    <AdminLocationButtons
                      isAdmin={isAdmin}
                      location={{
                        uuid: location.uuid,
                        networkId: location.networkUuid,
                      }}
                    />
                  )}
                </div>
              </div>
              {changeBook && (
                <div className="absolute bottom-0 left-0  w-full px-6 py-8">
                  <AddtoCart
                    showSoldoutStatus={true}
                    addLocation={addLocation}
                    fullWidth
                    loading={bookLoading}
                    isInList={isBooked}
                    isDisable={
                      (location?.country === 'Romania' && location?.onboardingStatus !== ILocationStatus.live) ||
                      location?.stockData?.stock?.color === 'soldout'
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </PortalNaked>
  );
};
export default LocationModal;
