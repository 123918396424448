import { oneLine } from 'common-tags';
import { ICampaigFormats } from 'interface';
import React from 'react';
import Slider from 'react-slick';
import FormatMediaCard from '../atoms/FormatMediaCart';

type IProps = {
  index: number;
  total: number;
  format: ICampaigFormats;
};

const CampaignFormat: React.FC<IProps> = ({ index, total, format }: IProps) => {
  const lineContainerClasses = oneLine`
  flex md:flex-col
  justify-between md:justify-start
  pb-3 md:pb-0

  `;

  const headerClasses = oneLine`
  text-base md:text-xs
  dark:text-dark-400
  font-medium
  tracking-tighter
  pb-1
  `;

  const valueClasses = oneLine`
  text-lg md:text-sm
  text-dark-default
  dark:text-white
  font-semibold md:font-bold
  text-right md:text-left
  `;

  let settings = {
    dots: false,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getTypeByRatio = (ratio: string) => {
    if (parseFloat(ratio) > 1) {
      return 'landscape';
    }
    return 'portrait';
  };

  return (
    <div className="flex flex-col format-list md:flex-row">
      <div className="md:w-5/12">
        <div className="relative flex items-center pb-4 pr-24">
          <h3 className="pr-3 mr-3 text-lg font-bold dark:text-dark-400 tracking-tighter border-r border-bordercolor dark:border-bordercolordark">
            Media
          </h3>
          <p className="text-xs font-medium dark:text-dark-400 tracking-tighter text-lightGrey">
            Format {index + 1} of {total}
          </p>
        </div>

        <div className="md:grid md:grid-cols-2 gap-7">
          <div className={lineContainerClasses}>
            <p className={headerClasses}>Ad Size (pixels)</p>
            <p className={valueClasses}>{format.minRes}</p>
          </div>
          <div className={lineContainerClasses}>
            <p className={headerClasses}>Type</p>
            <p className={valueClasses}>{getTypeByRatio(format.ratio)}</p>
          </div>
          <div className={lineContainerClasses}>
            <p className={headerClasses}>Ratio</p>
            <p className={valueClasses}>{format.ratio}</p>
          </div>
          <div className={lineContainerClasses}>
            <p className={headerClasses}>Duration</p>
            <p className={valueClasses}>{format.duration}</p>
          </div>
        </div>
      </div>
      <div className="md:w-7/12">
        <Slider {...settings} className={``}>
          {format.media.map((media, i) => {
            return <FormatMediaCard key={i} rules={media.rules} ready={media.ready} uuid={media.uuid} />;
          })}
        </Slider>
      </div>
    </div>
  );
};
export default CampaignFormat;
