import { useState, useEffect, useCallback, useRef } from 'react';
import { Campaigns } from '@api/campaigns';
import { ICampaignPublish, IPublishType } from '../context/publish/publish.reducer';
import { ICampaignFormat } from '../interface/ILocation';

interface IUsePriceCalculatorProps {
  publishState: ICampaignPublish;
  dispatch: (action: { type: string; payload: any }) => void;
}

interface ILocationReach {
  location_id: string;
  total: number;
}

interface ICalculatorState {
  price: string;
  loading: boolean;
  reach: number;
  devices: number;
  locations: number;
  slots: number;
  totalRunTime: number;
  plays: number;
}

const initialState: ICalculatorState = {
  loading: false,
  price: '0',
  reach: 0,
  totalRunTime: 0,
  devices: 0,
  locations: 0,
  slots: 0,
  plays: 0,
};

export const usePriceCalculator = ({ publishState, dispatch }: IUsePriceCalculatorProps) => {
  const [state, setState] = useState<ICalculatorState>(initialState);
  const refTimer = useRef<NodeJS.Timeout | null>(null);
  const cancelTokenSourceRef = useRef<any>(null);

  const calculatePrice = useCallback(async () => {
    setState(prev => ({ ...prev, loading: true }));
    if (state.loading) {
      if (refTimer.current) clearTimeout(refTimer.current);
      refTimer.current = setTimeout(() => {
        setState(prev => ({ ...prev, loading: false }));
      }, 5000);
      return false;
    }
    if (state.loading) return false;
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('New request initiated');
    }
    if (publishState.locations && publishState.locations.length > 0) {
      const circuitsSum = publishState.locations.reduce((acc, el) => acc + el.location.circuit, 0);
      setState(prev => ({ ...prev, devices: circuitsSum, locations: publishState.locations?.length || 0 }));
      let totalRunTime = 0;
      let totalSlots = 0;
      let totalPlays = 0;
      let submitData = [];

      const formatMap: {
        [key: string]: ICampaignFormat;
      } = {};
      publishState.formats.forEach(format => {
        format.locations.forEach(location => {
          formatMap[location] = format;
        });
      });

      if (publishState.schedule) {
        const cSlots = publishState.schedule
          .filter(sch => sch.uuid !== 'global' && sch.locations.length > 0)
          .reduce((acc, el) => {
            return acc + el.slots.length;
          }, 0);
        const cPlays = publishState.schedule
          .filter(sch => sch.uuid !== 'global' && sch.locations.length > 0)
          .reduce((acc, el) => {
            return acc + ((el.slots.length * 3600) / el.pph) * el.locations[0].circuit;
          }, 0);

        for (const item of publishState.schedule) {
          if (item.uuid !== 'global' && item.slots.length > 0 && item.locations.length > 0) {
            totalSlots += item.slots.length;
            const duration = publishState.formats.find(format => format.locations.includes(item.locations[0].uuid));
            item.locations[0].duration = duration?.selectedDuration || duration?.durations[0] || 10;

            const circuit = item.locations[0].circuit;

            const pph = item.pph;

            totalPlays += ((item.slots.length * 3600) / pph) * circuit;
            totalRunTime += ((item.slots.length * 3600) / pph) * circuit * item.locations[0].duration;

            submitData.push({ locations: item.locations, slots: item.slots });
          }
        }
        if (submitData.length > 0) {
          Promise.allSettled([
            Campaigns.calculatePrice(submitData, cancelTokenSourceRef?.current?.token),
            Campaigns.calculateReach(submitData, cancelTokenSourceRef?.current?.token),
          ])
            .then(([priceData, reactCalculate]) => {
              if (reactCalculate.status === 'fulfilled' && publishState.locations) {
                for (const item of publishState.locations) {
                  const values = reactCalculate.value as ILocationReach[] | undefined;

                  const calculate = values?.find(el => el.location_id === item.location.uuid);
                  if (calculate) {
                    const durationObj = formatMap[item.location.uuid];
                    const pph = 3600 / (item.pph === 0 ? 3600 / item.location.frequencies[0].seconds : item.pph);
                    const durationFactor = durationObj
                      ? durationObj.selectedDuration / item.location.acceptedDurations.sort((a, b) => a - b)[0]
                      : 1;

                    const calc = calculate.total * pph * durationFactor;
                    item.reach = calculate.total * pph;
                    item.calculatedReach = calc;
                    totalReach += calc;
                  }
                }
              }

              dispatch({
                type: IPublishType.setAgregateData,
                payload: {
                  price: priceData.status === 'fulfilled' ? priceData.value : '0',
                  reach: totalReach,
                },
              });
              setState(prev => ({
                loading: false,
                devices: circuitsSum,
                locations: publishState.locations?.length || 0,
                slots: totalSlots,
                totalRunTime,
                plays: totalPlays,
                price: priceData.status === 'fulfilled' ? priceData.value : '0',
                reach: totalReach,
              }));
            })
            .finally(() => {
              setState(prev => ({ ...prev, loading: false }));
            });
        } else {
          setState(prev => ({
            ...prev,
            devices: circuitsSum,
            loading: false,
            slots: 0,
            totalRunTime: 0,
            plays: 0,
            price: '0',
            reach: 0,
          }));
        }
      } else {
        setState(prev => ({
          ...prev,
          loading: false,
          slots: 0,
          totalRunTime: 0,
          plays: 0,
          price: '0',
          reach: 0,
        }));
      }
    } else {
    }

    // setState(prev => ({ ...prev, slots: totalSlots, plays: totalPlays }));

    let data = '0';
    let totalReach = 0;
  }, [publishState, dispatch]);

  useEffect(() => {
    if (publishState?.name.length > 0) {
      calculatePrice().catch(err => console.error('Failed to calculate price:', err));
    }
  }, [publishState?.toggleSlots]);

  useEffect(() => {
    if (publishState?.name.length > 0) {
      if (publishState.schedule) {
        let totalRunTime = 0;
        let totalReach = 0;

        for (const item of publishState.schedule) {
          if (item.uuid !== 'global' && item.slots.length > 0 && item.locations.length > 0) {
            const circuit = item.locations[0].circuit;
            const foundFormat = publishState.formats.find(format => format.locations.includes(item.locations[0].uuid));
            const location = publishState.locations?.find(loc => loc.location.uuid === item.locations[0].uuid);
            const duration = foundFormat?.selectedDuration
              ? parseInt(`${foundFormat?.selectedDuration}`, 10)
              : item.locations[0].duration;
            const durationFactor = duration
              ? duration / item.locations[0].acceptedDurations.sort((a, b) => a - b)[0]
              : 1;

            const pph = item.pph;

            totalRunTime += ((item.slots.length * 3600) / pph) * circuit * duration;
            if (location) {
              totalReach += location.reach * durationFactor;
            }
          }
        }
        setState(prev => ({ ...prev, totalRunTime, reach: totalReach }));
      }
    }
  }, [publishState?.recalculateReach]);

  useEffect(() => {
    if (publishState?.price) {
      setState(prev => ({ ...prev, price: publishState.price }));
    }
  }, [publishState?.price]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current);
      }
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Component unmounted');
      }
    };
  }, []);

  return {
    state,
    setState,
    calculatePrice,
  };
};
