import { IPublishType } from '../context/publish/publish.reducer';
import { CAMPAIGNS, TPSAPI } from '@api/index';
import React, { useEffect } from 'react';
import { PublishContext } from '../context/publish/publish.provider';
import { ILocationFull } from '../interface/ILocation';
import { useQuery } from 'react-query';
import { getScreen } from '@api/users/TpsApi';

const useLocationModal = ({ location }: { location: string }) => {
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [bookLoading, setBookLoading] = React.useState(false);

  const query = useQuery(['mondayCampaigns', location], () => TPSAPI.getScreen(location), {
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled: false,
  });

  useEffect(() => {
    if (location) {
      query.refetch();
    }
  }, [location]);

  return {
    query,
    bookLoading,
    setBookLoading,
  };
};

export default useLocationModal;
