import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { CAMPAIGNS } from '@api/index';
import { ICampaignPublish } from '../context/publish/publish.reducer';

export const useSaveDraft = (publishState: ICampaignPublish, delay: number = 10000) => {
  const debouncedFnRef = useRef<any>(null);

  useEffect(() => {
    const saveDraft = async (data: ICampaignPublish) => {
      try {
        await CAMPAIGNS.saveCampaignDraft({ ...data, maxStep: 3 });
      } catch (err) {
        console.error('Failed to save draft:', err);
      }
    };

    debouncedFnRef.current = debounce(saveDraft, delay);

    return () => {
      if (debouncedFnRef.current?.cancel) {
        debouncedFnRef.current.cancel();
      }
    };
  }, [delay]);

  const save = useCallback(() => {
    if (debouncedFnRef.current) {
      debouncedFnRef.current(publishState);
    }
  }, [publishState]);

  const saveNow = useCallback(async () => {
    try {
      await CAMPAIGNS.saveCampaignDraft({ ...publishState, maxStep: 3 });
    } catch (err) {
      console.error('Failed to save draft:', err);
    }
  }, [publishState]);

  const cancel = useCallback(() => {
    if (debouncedFnRef.current?.cancel) {
      debouncedFnRef.current.cancel();
    }
  }, []);

  return { save, saveNow, cancel };
};
