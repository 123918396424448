import React, { useState, useEffect, useRef, memo } from 'react';
import { Icon } from '@iconify/react-with-api';

const AnimatedStatus = ({ hasError, label }: { hasError?: boolean; label?: string }) => {
  return (
    <div className="status-container">
      <div
        className={`status-icon error-icon excl-mark cursor-pointer ${hasError ? 'active animate-entrance' : ''}`}
        data-tip
        data-for={label}
      >
        ?
      </div>

      <div
        className={`status-icon success-icon absolute checked-mark ${!hasError ? 'active animate-entrance' : 'hidden'}`}
      >
        <Icon className="inline icon-vertical-fix" height="1rem" width="1rem" icon="bi:check-lg" />
      </div>
    </div>
  );
};

export default memo(AnimatedStatus);
