import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { oneLine } from 'common-tags';
import { Stack } from 'components/layout';
import { Button, Input, RadioBox, SelectInput } from '.';
import { JWT, TPSAPI } from '@api/users';
import { useApp } from 'components/app';
import { ILoginResponse } from 'interface';
import { completedSurveyForm } from '@api/gtm/gtm';
import { IOptions, IRadioBoxOptions } from '../../../interface/ISelect';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { getValue } from '@amcharts/amcharts4/.internal/core/utils/Type';

type IProps = {
  onSubmit: () => void;
};

const companySize: IOptions[] = [
  { value: '1 - 10', label: '1 - 10' },
  { value: '11 - 50', label: '11 - 50' },
  { value: '51 - 100', label: '51 - 100' },
  { value: '101 - 500', label: '101 - 500' },
  { value: '500+', label: '500+' },
];

const HaveYouRanked: IRadioBoxOptions[] = [
  { value: 'yes', label: 'Yes', checked: false },
  { value: 'no', label: 'No', checked: false },
];
const Budget: IRadioBoxOptions[] = [
  { value: '$1-10k', label: '$1-10k', checked: false },
  { value: '$10-50k', label: '$10-50k', checked: false },
  { value: '$50-100k', label: '$50-100k', checked: false },
  { value: '$100-250k', label: '$100-250k', checked: false },
  { value: '$251k+', label: '$251k+', checked: false },
];
type FormInput = {
  companySize: string;
  website: string;
  role: string;
  ranCampaignBefore: boolean;
  budget: string;
  hearAboutUs: string;
  otherComments?: string;
};

const SurveyModal = ({ onSubmit }: IProps) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorSelection, setErrorSelection] = useState<boolean>(false);
  const [errorOtherRequired, setErrorOtherRequired] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const { notify, setReloadJwt, reloadJwt } = useApp();
  const bottomRef = useRef<HTMLDivElement>(null);

  const [vatTypes, setVatTypes] = useState<any[]>([
    {
      id: 1,
      title: 'Instagram or Facebook',
    },
    {
      id: 2,
      title: 'LinkedIn',
    },
    {
      id: 3,
      title: 'Twitter / X ',
    },
    {
      id: 4,
      title: 'Search Engine (Google, Bing etc.)',
    },
    {
      id: 5,
      title: 'Saw us on a billlboard',
    },
    {
      id: 6,
      title: 'Email or newsletter',
    },
    {
      id: 7,
      title: 'Word of mouth',
    },
    {
      id: 8,
      title: 'YouTube',
    },
    {
      id: 9,
      title: 'Tiktok',
    },
    {
      id: 10,
      title: 'AI / LLM platforms',
    },
  ]);
  const validateFn = (value: { hearAboutUs?: string; otherComments?: string }) => {
    const hearAboutUs = (value.hearAboutUs || '').trim();
    const otherComments = (value.otherComments || '').trim();
    return hearAboutUs !== '' || otherComments !== '';
  };

  const validationSchema = Yup.object()
    .shape({
      companySize: Yup.string().required(
        intl.formatMessage({ defaultMessage: 'This field is required', id: 'Error.requiredField' })
      ),
      ranCampaignBefore: Yup.string().required(
        intl.formatMessage({ defaultMessage: 'This field is required', id: 'Error.requiredField' })
      ),
      role: Yup.string().required(
        intl.formatMessage({ defaultMessage: 'This field is required', id: 'Error.requiredField' })
      ),
      // Remove individual required validations:
      hearAboutUs: Yup.string(),
      otherComments: Yup.string(),
      budget: Yup.string().required(
        intl.formatMessage({ defaultMessage: 'This field is required', id: 'Error.requiredField' })
      ),
      website: Yup.string().min(
        3,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '3' }
        )
      ),
    })
    .test('at-least-one', 'Please select an option or leave a comment', validateFn);

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner?.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit, formState, errors, watch, getValues, setValue, control, clearErrors } =
    useForm<FormInput>({
      resolver,
      reValidateMode: 'onChange',
    });

  const submitSurvey = async (values: FormInput) => {
    setIsLoading(true);

    try {
      const q1 =
        values.hearAboutUs.length > 0
          ? vatTypes.find(x => x.id === parseInt(values.hearAboutUs, 10))?.title
          : values.otherComments || values.otherComments;
      const obj = {
        ...values,
        q1: q1,
      };
      console.log('obj', obj);
      const response = await TPSAPI.addSurveyAnswer(obj);
      if (response.message === 'Survey post') {
        if (response.token) {
          JWT.setJwt(response.token as unknown as ILoginResponse);
          setReloadJwt(reloadJwt + 1);
        }
        completedSurveyForm(inputValue);
        onSubmit();
      } else {
        notify('Oops, something went wrong!', 'error', 'top-right');
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      notify('Oops, something went wrong!', 'error', 'top-right');
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    } finally {
      setIsLoading(false);
    }
  };
  const { dirtyFields } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  const hearAboutUsValue = watch('hearAboutUs', '0');

  const onError = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <form onSubmit={handleSubmit(submitSurvey, onError)}>
      <div
        className={oneLine`fixed flex justify-center overflow-hidden items-center z-110 inset-0 bg-gray-800 bg-opacity-70 overflow-y-auto w-full h-full light-box-container`}
      >
        <div className={oneLine`mx-4 modal-max-h flex flex-col bg-white dark:bg-dark-200 items-center rounded-2xl`}>
          <div className="h-full p-6 overflow-y-auto pb-0 w-full lg:min-w-[580px]">
            <Stack align="center" gravity="center" spacing="xl">
              <div className="relative z-50 w-full ">
                <div className="flex flex-col mb-4">
                  <h3 className="text-md font-bold  dark:text-white">Tell us a bit about yourself! 🌟 </h3>
                </div>
                <div>
                  <div>
                    <Input
                      name="website"
                      innerRef={register}
                      type="text"
                      label={''}
                      validation={getValidation('website')}
                      placeholder="Company website"
                      fullWidth={true}
                      defaultValue={''}
                      autoFocus={true}
                    />
                  </div>
                  <div className="lg:grid lg:grid-cols-2 gap-6 space-y-6 lg:space-y-0 py-4">
                    <Input
                      name="role"
                      innerRef={register}
                      validation={getValidation('role')}
                      type="text"
                      label={''}
                      placeholder="Your role"
                      fullWidth={true}
                      defaultValue={''}
                      autoFocus={true}
                    />
                    <Controller
                      name="companySize"
                      control={control}
                      render={({ onChange, value, onBlur, ref }) => (
                        <SelectInput
                          options={companySize}
                          onChange={e => {
                            onChange(e ? e.value : '');
                          }}
                          validation={getValidation('companySize')}
                          placeholderText="Company size"
                          isMulti={false}
                          fullWidth={true}
                          isClearable={false}
                          selName="com"
                          label=""
                        />
                      )}
                      defaultValue={''}
                    />
                  </div>
                  <div className="lg:grid lg:grid-cols-2 gap-6 space-y-6 lg:space-y-0 pt-4">
                    <div>
                      <p className="text-sm font-semibold text-dark-500 dark:text-dark-300 mt-2 text-left pb-4">
                        Have you run OOH campaigns before?
                      </p>

                      <Controller
                        name="ranCampaignBefore"
                        control={control}
                        render={({ onChange }) => (
                          <RadioBox
                            boxSize="small"
                            key={`1`}
                            type="radio"
                            name={`map-view2`}
                            flexClass="flex-1 is-center-box"
                            onChange={(e: any) => {
                              const l = e.target.value;
                              onChange(l === 'yes');
                            }}
                            options={HaveYouRanked}
                          />
                        )}
                        defaultValue=""
                      />
                      {getValidation('hearAboutUs')?.error?.message ? (
                        <div className="text-danger-500 w-fit text-xs pt-2 pl-3.5 space-x-1 collapsible-error expanded">
                          {getValidation('hearAboutUs')?.error.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="pt-0">
                    <p className="text-sm font-semibold text-dark-500 dark:text-dark-300 mt-2 text-left py-4">
                      What budget are you considering for your next campaign?
                    </p>
                    <Controller
                      name="budget"
                      control={control}
                      render={({ onChange }) => (
                        <RadioBox
                          boxSize="small"
                          key={`2`}
                          type="radio"
                          name={`map-view`}
                          flexClass="flex-1 is-center-box"
                          onChange={(e: any) => {
                            const l = e.target.value;
                            onChange(l);
                          }}
                          options={Budget}
                        />
                      )}
                      defaultValue=""
                    />
                    {getValidation('budget')?.error?.message ? (
                      <div className="text-danger-500 w-fit text-xs pt-2 pl-3.5 space-x-1 collapsible-error expanded">
                        {getValidation('budget')?.error.message}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="pt-6 pb-12">
                  <p className="text-sm font-semibold text-dark-500 dark:text-dark-300 mt-2 text-left pb-4">
                    Where did you hear about us? Your answer means a lot!
                  </p>
                  <Controller
                    name="hearAboutUs"
                    control={control}
                    render={({ onChange, value }) => {
                      return (
                        <div className="grid survey-modal-grid items-start justify-start h-full w-full flex-wrap gap-6">
                          {vatTypes.slice(0, 10).map((vatType: any) => {
                            return (
                              <div
                                key={vatType.id}
                                className={`flex flex-row w-full dark:bg-dark-300 dark:bg-opacity-20 bg-[#D9D9D9] dark:text-white text-dark-200 rounded-2xl whitespace-nowrap py-2 px-6  cursor-pointer
                      border
                      ${
                        getValues('hearAboutUs') === vatType.id
                          ? 'border-[#EB3F3F]'
                          : 'border-dark-300 border-opacity-20'
                      }
                      `}
                                onClick={() => {
                                  onChange(vatType.id);
                                }}
                              >
                                <div
                                  className={`w-5 h-5 border ${
                                    value === `${vatType.id}` ? 'border-[#EB3F3F]' : 'border-dark-300'
                                  } bg-white rounded-2xl flex items-center justify-center mr-4`}
                                >
                                  <div
                                    className={`w-2 h-2 ${
                                      value === vatType.id ? 'bg-[#EB3F3F]' : 'bg-white'
                                    } rounded-2xl`}
                                  ></div>
                                </div>
                                {vatType.title}
                              </div>
                            );
                          })}
                        </div>
                      );
                    }}
                  />
                </div>
                <div className="grid grid-cols-1  items-start justify-start h-full w-full flex-wrap">
                  <div>
                    <Input
                      name="otherComments"
                      innerRef={register}
                      type="text"
                      label={''}
                      validation={getValidation('')}
                      placeholder="Other"
                      fullWidth={true}
                      defaultValue={''}
                      autoFocus={true}
                      onFocus={() => {
                        // Reset the hearAboutUs field when focusing on otherComments
                        setValue('hearAboutUs', '', { shouldValidate: true, shouldDirty: true });
                      }}
                    />
                  </div>
                </div>
              </div>
            </Stack>

            <div ref={bottomRef}></div>
          </div>
          <div className="w-full p-6 pt-8">
            <Button type="submit" color="primary" disabled={isLoading} fullWidth={true}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SurveyModal;
