import { ILoginResponse, IUser } from 'interface';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import jwt_decode from 'jwt-decode';
import LogRocket from 'logrocket';
const maxAge = 30 * 24 * 60 * 60;

const setJwt = (response: ILoginResponse): IUser => {
  setCookie(null, 'jwt', response.accessToken, {
    maxAge,
    path: '/',
  });
  if (response.refreshToken)
    setCookie(null, 'refreshToken', response.refreshToken, {
      maxAge,
      path: '/',
    });

  setCookie(null, 'user', '1', {
    maxAge,
    path: '/',
  });
  const jwt: any = jwt_decode(response.accessToken);
  let l: IUser = {
    id: jwt.sub,
    exp: jwt.exp,
    firstName: jwt.firstName,
    lastName: jwt.lastName,
    emailValidation: jwt.emailValidation,
    role: jwt.role,
    avatar: jwt.avatar,
    hasCompany: jwt.raw.hasCompany,
    email: jwt.email,
    campaign_prefix: jwt.raw?.campaign_prefix,
    firstLogin: jwt?.firstLogin || null,
    extra: jwt?.extra || null,
  };
  return l;
};

const getJwtUser = (): IUser => {
  const cookies = parseCookies();
  let jwt = cookies.jwt;
  try {
    if (jwt) {
      const jwtDecode: any = jwt_decode(jwt);
      let l: IUser = {
        id: jwtDecode.sub,
        exp: jwtDecode.exp,
        firstName: jwtDecode.firstName,
        lastName: jwtDecode.lastName,
        emailValidation: jwtDecode.emailValidation,
        email: jwtDecode.email,
        avatar: jwtDecode.avatar,
        hasCompany: jwtDecode.raw?.hasCompany,
        role: jwtDecode.role,
        campaign_prefix: jwtDecode.raw?.campaign_prefix,
        firstLogin: jwtDecode?.firstLogin,
        extra: jwtDecode?.extra,
      };

      LogRocket.identify(jwtDecode.sub, {
        name: `${jwtDecode.firstName} ${jwtDecode.lastName}`,
        email: jwtDecode.email,
        role: jwtDecode.role,
      });

      return l;
    } else {
      let l: IUser = {
        id: '',
        exp: 0,
        firstName: '',
        lastName: '',
        emailValidation: false,
        role: '',
        avatar: '{}',
        hasCompany: '0',
        email: '',
        campaign_prefix: '',
        firstLogin: false,
        extra: null,
      };
      return l;
    }
  } catch (error) {
    let l: IUser = {
      id: '',
      firstName: '',
      lastName: '',
      emailValidation: false,
      role: '',
      avatar: '{}',
      exp: 0,
      hasCompany: '0',
      email: '',
      campaign_prefix: '',
      firstLogin: false,
      extra: null,
    };
    return l;
  }
};

const JWT = {
  setJwt,
  getJwtUser,
};

export default JWT;
