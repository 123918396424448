import { oneLine } from 'common-tags';
import ReactTooltip from 'react-tooltip';

export type CAMPAIGN_STATUS =
  | 'pending'
  | 'completed'
  | 'ready'
  | 'processing'
  | 'running'
  | 'rejected'
  | 'deleted'
  | 'draft'
  | 'Draft'
  | 'paused'
  | 'saved'
  | 'varies'
  | 'PROCESSING'
  | 'PENDING'
  | 'PAUSED'
  | 'CONTENT_REJECTED_INTERNALLY'
  | 'ALL_APPROVED_CONTENT'
  | 'CONTENT_REJECTED_EXTERNALLY'
  | 'SUBMITTED_FOR_APPROVAL'
  | 'APPROVED_CONTENT'
  | 'READY_TO_START'
  | 'RUNNING'
  | 'COMPLETED'
  | 'Published'
  | '';

type StatusTagProps = {
  text: string;
  status: CAMPAIGN_STATUS;
  hasCustomText?: boolean;
  hasDescription?: boolean;
  otherClasses?: string;
  fullWidth?: boolean;
};

type StatusTagObject = {
  title: string;
  description: string;
  class: string;
};

const getStatusTagObject = (text: string): StatusTagObject => {
  switch (text) {
    case 'rejected':
      return {
        title: 'Rejected',
        description: 'Rejected',
        class: 'text-danger-500 bg-danger-500 bg-opacity-30',
      };
    case 'deleted':
      return {
        title: 'Deleted',
        description: 'Deleted',
        class: 'text-danger-500 bg-danger-500 bg-opacity-30',
      };
    case 'draft':
      return {
        title: 'Draft',
        description: 'Your campaign is not published yet!',
        class: 'text-lightGrey bg-lightGrey bg-opacity-30',
      };
    case 'processing':
    case 'PROCESSING':
      return {
        title: 'Processing',
        description: 'Processing',
        class: 'text-purpule-400 bg-purpule-400 bg-opacity-30',
      };
    case 'pending':
    case 'PENDING':
      return {
        title: 'Internal review',
        description: 'Waiting for Blindspot Admin approval',
        class: 'text-yellow-500 bg-yellow-500 bg-opacity-30',
      };
    case 'varies':
      return {
        title: 'Internal review',
        description: 'Waiting for Blindspot Admin approval',
        class: 'text-yellowStatus-default bg-yellowStatus-100 bg-opacity-20',
      };
    case 'paused':
    case 'PAUSED':
      return {
        title: 'Paused',
        description: 'Paused',
        class: 'text-orange-500 bg-orange-500 bg-opacity-30',
      };
    case 'CONTENT_REJECTED_INTERNALLY':
      return {
        title: 'Content rejected internally',
        description: 'Content rejected internally',
        class: 'text-danger-500 bg-danger-500 bg-opacity-30',
      };
    case 'CONTENT_REJECTED_EXTERNALLY':
      return {
        title: 'Content rejected by landlord',
        description: 'Content rejected by landlord',
        class: 'text-danger-500 bg-danger-500 bg-opacity-30',
      };
    case 'SUBMITTED_FOR_APPROVAL':
      return {
        title: 'Waiting for Media approval',
        description: 'Waiting for Media approval',
        class: 'text-yellow-500 bg-yellow-500 bg-opacity-30',
      };
    case 'APPROVED_CONTENT':
      return {
        title: 'Some Media is approved',
        description: 'Some Media is approved',
        class: 'text-green-500 bg-green-500 bg-opacity-30',
      };
    case 'ALL_APPROVED_CONTENT':
      return {
        title: 'All Media is approved',
        description: 'All Media is approved',
        class: 'text-green-500 bg-green-500 bg-opacity-30',
      };
    case 'ready':
    case 'READY_TO_START':
      return {
        title: 'Scheduled',
        description: 'Scheduled',
        class: 'text-green-500 bg-green-500 bg-opacity-30',
      };
    case 'running':
    case 'RUNNING':
      return {
        title: 'Started',
        description: 'Your campaign is running!',
        class: 'text-blue-500 bg-blue-500 bg-opacity-30',
      };
    case 'completed':
    case 'COMPLETED':
      return {
        title: 'Completed',
        description: 'Campaign successfully completed!',
        class: 'text-green-500 bg-green-500 bg-opacity-30',
      };
    case 'Published':
      return {
        title: 'Published',
        description: 'Published',
        class: 'text-purpule-400 bg-purpule-400 bg-opacity-30',
      };
    case 'saved':
      return {
        title: 'Saved',
        description: 'Saved',
        class: 'text-lightGrey bg-lightGrey bg-opacity-30',
      };
    default:
      return {
        title: 'Draft',
        description: 'Your campaign is not published yet!',
        class: 'text-lightGrey bg-lightGrey bg-opacity-30',
      };
  }
};

export const StatusTag = ({
  status,
  text,
  fullWidth,
  otherClasses,
  hasCustomText = false,
  hasDescription = false,
}: StatusTagProps) => {
  return (
    <>
      {hasDescription && (
        <ReactTooltip id={`${text}-main`} place="bottom" className="w-64 text-center custom-tooltip">
          <div className="preview-media-tooltip">{getStatusTagObject(text)?.description}</div>
        </ReactTooltip>
      )}
      <div
        title={hasCustomText ? text : getStatusTagObject(text)?.title}
        data-for={`${text}-main`}
        data-tip="Text for high number of request"
        data-iscapture="true"
        className={oneLine`status-tag max-w-full text-container items-center justify-center font-button px-4 py-1
        font-medium  appearance-none rounded-3xl
        ease-linear tracking-wide
       	capitalize
        text-xs
        leading-loose
        ${fullWidth ? 'w-full' : 'w-max'}
        transition duration-150 backdrop-blur-3xl
        ${hasDescription ? 'cursor-pointer' : ''} 
        ${otherClasses ? otherClasses : ''} 
        ${getStatusTagObject(status)?.class}
        `}
      >
        {hasCustomText ? text : getStatusTagObject(text)?.title}
      </div>
    </>
  );
};
