import { useEffect } from 'react';
import { JWT } from '@api/users';

const KommunicateChat = () => {
  const user = JWT.getJwtUser();

  let usedUser: {
    userId: string | null;
    userName: string | null;
    email: string | null;
    password: string | null;
    displayName: string | null;
  } = {
    userId: null,
    userName: null,
    email: null,
    password: null,
    displayName: null,
  };
  if (user && user.id !== '') {
    usedUser = {
      userId: JWT.getJwtUser().id,
      userName: `${JWT.getJwtUser().firstName} ${JWT.getJwtUser().lastName}`,
      displayName: `${JWT.getJwtUser().firstName} ${JWT.getJwtUser().lastName}`,
      email: JWT.getJwtUser()?.email,
      password: JWT.getJwtUser()?.id,
    };
  }

  useEffect(() => {
    // eslint-disable-next-line func-names
    (function (d, m) {
      let kommunicateSettings = {
        appId: '68c246972bb6d2ce3957bff50ce7bad1',
        popupWidget: true,
        automaticChatOpenOnNavigation: true,
        ...usedUser,
      };
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.kommunicate.io/v2/kommunicate.app';
      let h = document.getElementsByTagName('head')[0];
      h.appendChild(s);
      window.kommunicate = m;
      m._globals = kommunicateSettings;
    })(document, window.kommunicate || {});
  }, []);

  return null;
};

export default KommunicateChat;
