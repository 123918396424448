/* eslint-disable no-bitwise */
/* eslint-disable no-cond-assign */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-var */
import { Features } from 'interface/ILocation';
import { IOptions } from 'interface/ISelect';
const spectacularStroke = 5;
const spectacularCircle = 8;

const normalStroke = 3;
const normaCircle = 10;

export const getOnlyBook = () => {
  if (localStorage.getItem('only_book')) {
    if (localStorage.getItem('only_book') === '1') return true;
    return false;
  }
  return false;
};

export const getComingSoon = () => {
  if (localStorage.getItem('coming_soon')) {
    if (localStorage.getItem('coming_soon') === '1') return true;
    return false;
  }
  return false;
};

export const getHasImage = () => {
  if (localStorage.getItem('has_image')) {
    if (localStorage.getItem('has_image') === '1') return true;
    return false;
  }
  return true;
};

export const filterLocations = (
  locations: Features[],
  searchTags: IOptions[],
  searchText: string,
  locationType: string[]
) => {
  return locations
    .filter(el => {
      let foundTags = 1;
      if (searchTags && searchTags.length > 0) {
        foundTags = 0;
        if (el.properties.tags) {
          let tags = el.properties.tags;
          const res = searchTags.map(e => e.value).filter(item => tags.includes(item));
          if (res.length > 0) {
            foundTags = 1;
          }
        }
      }
      const comingSoonData = getComingSoon();
      const hasImageData = getHasImage();
      let foundUnavailable = 1;

      if (!comingSoonData) {
        foundUnavailable = 0;
        if (el.properties.requests) {
          let r = el.properties.requests;
          if (
            el.properties.uuid === '19902366-b522-446f-ac65-5709bf2cffb7' ||
            el.properties.uuid === '51ff26c9-2e16-4b3e-a6d5-4d2cd8676d7b'
          ) {
          }
          if (r.color === 'green' || r.color === 'yellow' || r.color === 'orange' || r.color === 'red') {
            foundUnavailable = 1;
          }
        }
      }

      let foundHasImage = 1;
      if (!hasImageData) {
        foundHasImage = 0;
        if (el.properties.hI) {
          foundHasImage = 1;
        }
      }
      return (
        el.properties.search.toLowerCase().includes(searchText.toLowerCase()) &&
        locationType.includes(el.properties.locationType[0]) &&
        foundTags === 1 &&
        foundUnavailable === 1 &&
        foundHasImage === 1
      );
    })
    .map(el => el.properties.uuid);
};

const layerStyle1 = {
  general: {
    id: 'places-general',
    source: 'places-general',
    type: 'circle',
    paint: {
      'circle-radius': normaCircle,
      'circle-stroke-width': normalStroke,
      'circle-stroke-color': '#fff',
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        '#F64747',

        ['boolean', ['feature-state', 'hover'], false],
        '#F64747',
        '#F1CB69',
      ],
    },
  },
  indoorNormal: {
    id: 'places-indoor-normal',
    source: 'places-indoor-normal',
    type: 'circle',
    paint: {
      'circle-radius': normaCircle,
      'circle-stroke-width': normalStroke,
      'circle-stroke-color': '#fff',
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        '#F64747',

        ['boolean', ['feature-state', 'hover'], false],
        '#F64747',
        '#F1CB69',
      ],
    },
  },
  indoorSpectacular: {
    id: 'places-indoor-spectacular',
    source: 'places-indoor-spectacular',
    type: 'circle',
    paint: {
      'circle-radius': spectacularCircle,
      'circle-stroke-width': spectacularStroke,
      'circle-stroke-color': '#F1CB69',
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        '#F64747',

        ['boolean', ['feature-state', 'hover'], false],
        '#F64747',
        '#000',
      ],
    },
  },
  outdoorNormal: {
    id: 'places-outdoor-normal',
    source: 'places-outdoor-normal',
    type: 'circle',
    paint: {
      'circle-radius': normaCircle,
      'circle-stroke-width': normalStroke,
      'circle-stroke-color': '#ffffff',
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        '#F64747',

        ['boolean', ['feature-state', 'hover'], false],
        '#F64747',
        '#CC9CF2',
      ],
    },
  },
  outdoorSpectacular: {
    id: 'places-outdoor-spectacular',
    source: 'places-outdoor-spectacular',
    type: 'circle',
    paint: {
      'circle-radius': spectacularCircle,
      'circle-stroke-width': spectacularStroke,
      'circle-stroke-color': '#CC9CF2',
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        '#F64747',

        ['boolean', ['feature-state', 'hover'], false],
        '#F53D3F',
        '#000',
      ],
    },
  },
  mobileNormal: {
    id: 'places-mobile-normal',
    source: 'places-mobile-normal',
    type: 'circle',
    paint: {
      'circle-radius': normaCircle,
      'circle-stroke-width': normalStroke,
      'circle-stroke-color': '#ffffff',
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        '#F64747',

        ['boolean', ['feature-state', 'hover'], false],
        '#F53D3F',
        '#B6EE9B',
      ],
    },
  },
  mobileSpectacular: {
    id: 'places-mobile-spectacular',
    source: 'places-mobile-spectacular',
    type: 'circle',
    paint: {
      'circle-radius': spectacularCircle,
      'circle-stroke-width': spectacularCircle,
      'circle-stroke-color': '#B6EE9B',
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        '#F64747',

        ['boolean', ['feature-state', 'hover'], false],
        '#F53D3F',
        '#000',
      ],
    },
  },
};

export const encodeLocation = (
  contentType: string,
  locationType: string,
  hasDeal: boolean,
  status: string,
  locationImages: number,
  networkImages?: number
) => {
  let encodedContentType = 0;
  let encodedLocationType = 0;
  let encodedHasDeal = 0;
  let encodedHasPictures = 0;
  let encodedStatus = 0;

  if (hasDeal) {
    encodedHasDeal = 1;
  }
  if (locationImages > 0 || (networkImages && networkImages > 0)) {
    encodedHasPictures = 1;
  }

  switch (status) {
    case 'live':
      encodedStatus = 1;
      break;
    case 'testing':
      encodedStatus = 2;
      break;
    case 'dev':
      encodedStatus = 3;
      break;
    case 'coming-soon':
      encodedStatus = 4;
      break;
    case 'soldout':
      encodedStatus = 5;
      break;
    default:
      break;
  }

  switch (contentType) {
    case 'static':
      encodedContentType = 1;
      break;
    case 'video':
      encodedContentType = 2;
      break;
  }
  switch (locationType) {
    case 'indoor':
      encodedLocationType = 1;
      break;
    case 'outdoor':
      encodedLocationType = 2;
      break;
    case 'mobile':
      encodedLocationType = 3;
      break;
    case 'poi':
      encodedLocationType = 4;
      break;
    default:
      break;
  }
  return `${encodedContentType}${encodedLocationType}${encodedHasDeal}${encodedStatus}${encodedHasPictures}${locationImages}${networkImages}`;
};

export type DecodedMetadata = {
  contentType: string;
  locationType: string;
  hasDeal: boolean;
  hasPictures: boolean;
  status: string;
  color: number[];
  locationImages: number;
  networkImages: number;
};
export const decodeLocation = (encodedLocation: string): DecodedMetadata => {
  const decodedLocation = {
    contentType: '',
    locationType: '',
    hasDeal: false,
    hasPictures: false,
    status: '',
    color: [0, 0, 0],
    locationImages: 0,
    networkImages: 0,
  };

  switch (encodedLocation[0]) {
    case '1':
      decodedLocation.contentType = 'banner';
      break;
    case '2':
      decodedLocation.contentType = 'video';
      break;
    default:
      break;
  }

  switch (encodedLocation[1]) {
    case '1':
      decodedLocation.locationType = 'indoor';
      decodedLocation.color = [241, 203, 105];
      break;
    case '2':
      decodedLocation.locationType = 'outdoor';
      decodedLocation.color = [204, 156, 242];
      break;
    case '3':
      decodedLocation.locationType = 'mobile';
      decodedLocation.color = [182, 238, 155];
      break;
    default:
      decodedLocation.color = [255, 255, 255];
      break;
  }

  if (encodedLocation[2] === '1') {
    decodedLocation.hasDeal = true;
  }

  switch (encodedLocation[3]) {
    case '1':
      decodedLocation.status = 'live';
      break;
    case '2':
      decodedLocation.status = 'testing';
      break;
    case '3':
      decodedLocation.status = 'dev';
      break;
    case '4':
      decodedLocation.status = 'coming-soon';
      break;
    case '5':
      decodedLocation.status = 'soldout';
      break;
    default:
      break;
  }

  if (encodedLocation[4] === '1') {
    decodedLocation.hasPictures = true;
  }
  if (encodedLocation[5] && encodedLocation[5] !== '0') {
    decodedLocation.locationImages = parseInt(encodedLocation[5]);
  }
  if (encodedLocation[6] && encodedLocation[6] !== '0') {
    decodedLocation.networkImages = parseInt(encodedLocation[6]);
  }

  return decodedLocation;
};

export const fuzzySearch = (element: string, inputString: string) => {
  var element = element.toLowerCase();
  let i = 0;
  let n = -1;
  let l;
  inputString = inputString.toLowerCase();
  for (; (l = inputString[i++]); ) if (!~(n = element.indexOf(l, n + 1))) return false;
  return true;
};

export const calculatePopupPosition = (
  pointX: number,
  pointY: number,
  viewportWidth: number,
  viewportHeight: number,
  popupWidth: number,
  popupHeight: number,
  pinWidth: number,
  pinHeight: number,
  leftMargin: number
) => {
  const marginX = 10; // Spațiu suplimentar dorit între pop-up și marginile viewportului pe axa X
  const marginY = 180; // Marginea superioară dorită între pop-up și marginea de sus a viewportului pe axa Y
  const margin = 10; // Spațiu suplimentar dorit între pop-up și marginile viewportului pe celelalte axe

  const pinOffsetLeft = pinWidth / 2;
  const pinOffsetTop = pinHeight;

  // Verifică dacă pop-up-ul poate fi plasat în partea stângă
  if (pointX - popupWidth - marginX - leftMargin >= 0) {
    // Verifică dacă pop-up-ul poate fi plasat complet în partea de sus
    if (pointY - popupHeight - marginY >= 0) {
      return {
        position: 'top-left',
        offsetTop: -(popupHeight + pinOffsetTop),
        offsetLeft: -pinOffsetLeft,
      };
    }
    // Verifică dacă pop-up-ul poate fi plasat complet în partea de jos
    else if (pointY + popupHeight + margin <= viewportHeight) {
      return {
        position: 'bottom-left',
        offsetTop: pinOffsetTop,
        offsetLeft: -pinOffsetLeft,
      };
    }
    // Pop-up-ul nu se poate plasa complet nici în partea de sus, nici în partea de jos
    else {
      return {
        position: 'left',
        offsetTop: -(popupHeight / 2),
        offsetLeft: -(popupWidth + pinOffsetLeft),
      };
    }
  }

  // Verifică dacă pop-up-ul poate fi plasat în partea dreaptă
  else if (pointX + popupWidth + marginX <= viewportWidth) {
    // Verifică dacă pop-up-ul poate fi plasat complet în partea de sus
    if (pointY - popupHeight - marginY >= 0) {
      return {
        position: 'top-right',
        offsetTop: -(popupHeight + pinOffsetTop),
        offsetLeft: pinOffsetLeft,
      };
    }
    // Verifică dacă pop-up-ul poate fi plasat complet în partea de jos
    else if (pointY + popupHeight + margin <= viewportHeight) {
      return {
        position: 'bottom-right',
        offsetTop: pinOffsetTop,
        offsetLeft: pinOffsetLeft,
      };
    }
    // Pop-up-ul nu se poate plasa complet nici în partea de sus, nici în partea de jos
    else {
      return {
        position: 'right',
        offsetTop: -(popupHeight / 2),
        offsetLeft: pinOffsetLeft,
      };
    }
  }

  // Verifică dacă pop-up-ul poate fi plasat complet în partea de sus
  else if (pointY - popupHeight - marginY >= 0) {
    return {
      position: 'top',
      offsetTop: -(popupHeight + pinOffsetTop),
      offsetLeft: -(popupWidth / 2),
    };
  }

  // Verifică dacă pop-up-ul poate fi plasat complet în partea de jos
  else if (pointY + popupHeight + margin <= viewportHeight) {
    return {
      position: 'bottom',
      offsetTop: pinOffsetTop,
      offsetLeft: -(popupWidth / 2),
    };
  }

  // Pop-up-ul nu poate fi plasat complet nici pe axele principale
  // În acest caz, pop-up-ul va fi plasat în poziția în care se încadrează cel mai bine
  // fără a depăși marginile viewportului
  return {
    position: 'left',
    offsetTop: -(popupHeight / 2),
    offsetLeft: -(popupWidth + pinOffsetLeft),
  };
};

export const getPoz = (popPoz: any) => {
  switch (popPoz) {
    case 'left':
      return 'right';
    case 'right':
      return 'left';
    case 'top':
      return 'bottom';
    case 'bottom':
      return 'top';
    case 'top-left':
      return 'bottom-right';
    case 'top-right':
      return 'bottom-left';
    case 'bottom-right':
      return 'top-left';
    case 'bottom-left':
      return 'top-right';
    default:
      return 'left';
  }
};

export const getOff = (popPoz: any) => {
  switch (popPoz) {
    case 'left':
      return [-25, -33];
    case 'right':
      return [+25, 33];
    case 'top':
      return [-50, 0];
    case 'bottom':
      return [0, 0];
    case 'top-left':
      return [-33, -30];
    case 'top-right':
      return [-33, 30];
    case 'bottom-right':
      return [0, 0];
    case 'bottom-left':
      return [0, -20];
    default:
      return [0, 0];
  }
};

export const findBoundingBoxCenter = (coordinates: any[]): { lat: number; lon: number } => {
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLong = Infinity;
  let maxLong = -Infinity;

  for (const coordinate of coordinates) {
    minLat = Math.min(minLat, coordinate.latitude);
    maxLat = Math.max(maxLat, coordinate.latitude);
    minLong = Math.min(minLong, coordinate.longitude);
    maxLong = Math.max(maxLong, coordinate.longitude);
  }

  const centerLatitude = (minLat + maxLat) / 2;
  const centerLongitude = (minLong + maxLong) / 2;

  return { lat: centerLatitude, lon: centerLongitude };
};

export const mapLayerPinsColors = layerStyle1;
