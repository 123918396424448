import { Campaigns } from 'api/campaigns';
import { Icon } from '@iconify/react-with-api';
import { oneLine } from 'common-tags';
import { useApp } from 'components/app';
import { IRule, IRulesApi, IRulesCode, IRulesMediaCard } from 'interface';
import { IMediaUpload } from 'interface/IMedia';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { doesItFit, getImageSize, isRatioInRange, checkRatio } from 'utils/mediaRatioUtils';
import { RuleChip, Rules } from '../atoms';
import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import { ChevronDown, Delete, Show } from 'react-iconly';
import { NotifyType } from '../atoms/Toast';
import { Popover } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';

const baseUrl = 'https://storage.googleapis.com/engage-transcoded-videos/';
const baseUrlUploaded = 'https://storage.googleapis.com/engage-uploaded-videos/';

type IProps = {
  data: IMediaUpload;
  resolution: [number, number];
  ratio: 1;
  duration: number;
  uuid: string;
  rules: IRulesApi[];
  index: number;

  rejectMedia: () => void;
  previewMedia: () => void;
  rejectMediaFaild: () => void;
  updateVideoDuration: (videoDuration: number) => void;
};
type IRatioCheck = {
  canUpload: number;
  message: string;
  type: NotifyType;
};
//const maxMediaFileSize = 41943040;
const maxMediaFileSize = 52428800 * 2;

const MediaCard = ({
  data,
  resolution,
  duration,
  ratio,
  rejectMedia,
  rejectMediaFaild,
  updateVideoDuration,
  previewMedia,
  rules,
  uuid,
  index,
}: IProps) => {
  const { notify } = useApp();
  const intl = useIntl();
  const { publishState, dispatch } = React.useContext(PublishContext);

  const [file, setFile] = React.useState<IMediaUpload>(data);
  const [src, setSrc] = React.useState('https://storage.googleapis.com/engage-uploaded-videos/blank_image.png');
  const [response, setResponse] = React.useState<IRatioCheck | null>();
  const [cardRules, setCardRules] = React.useState<IRulesMediaCard[]>([]);
  const [percentCompleted, setPercentCompleted] = React.useState<number>(0);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [uploadingText, setUploadingText] = React.useState<string>('Uploading');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    verifyMedia();
    setCardRules(data.rules);
  }, []);
  useEffect(() => {
    if (response) {
      if (response.canUpload === 0) {
        notify(response.message, response.type);
        setResponse(null);
        rejectMedia();
      }
      if (response.canUpload === 1) {
        uploadMedia();
      }
    }

    return () => {};
  }, [response]);
  const uploadMedia = async () => {
    if (file.action === 'toupload') {
      setIsUploading(true);

      const responseData = await Campaigns.uploadMedia(
        { ...file, width: resolution[0], height: resolution[1] },
        onUploadProgress
      );
      if (responseData.status !== 200) {
        let f = file;
        f.action = 'faildUpload';
        f.filename = file.oldName;
        // f.duration = duration;

        setFile(f);
        rejectMediaFaild();
        notify('Please try again.', 'error', undefined, 'Uploading media failed!');
      } else {
        let f = file;
        f.action = 'isuploaded';
        f.filename = file.oldName;
        // f.duration = duration;
        setFile(f);
        dispatch({
          type: IPublishType.addMediaToFormat,
          payload: {
            file: f,
            uuid: uuid,
          },
        });
      }
    }
    setIsUploading(false);
  };
  const onUploadProgress = (progressEvent: any) => {
    let percentCompletedField = parseInt(`${Math.round((progressEvent.loaded / progressEvent.total) * 100)}`, 10);
    if (percentCompleted > 99) setUploadingText('Processing');
    setPercentCompleted(percentCompletedField);
  };
  const verifyMedia = () => {
    // if (true) {
    const reader = new FileReader();
    if (data.file.type && data.file.type.match('image')) {
      reader.onload = e => {
        // setSrc(e.target.result);

        if (data.file.size > maxMediaFileSize) {
          notify(`Media Size is too large for ${data.file.name}. Max size is 40 MB.`, 'error');
          rejectMedia();
        } else {
          if (reader && reader.result)
            getImageSize(reader.result, (width: any, height: any) => {
              const inputImage = {
                width,
                height,
              };
              const targetVideo = {
                width: resolution[0],
                height: resolution[1],
              };
              const isValid = doesItFit(inputImage, targetVideo, 3);

              let dataRatio = checkRatio(file.file.name, isValid, `${resolution[0]}x${resolution[1]}`);
              setResponse(dataRatio);
            });
        }
        if (reader.result) {
          setSrc(reader.result as string);
        }
      };
      reader.readAsDataURL(file.file);
    } else if (data.type === 'draft') {
      const ext = data.oldName.split('.');

      if (data.oldType.match('image')) {
        if (data.oldUid) setSrc(`${baseUrlUploaded}${data.oldUid}.${ext[ext.length - 1]}`);
        else setSrc(`${baseUrlUploaded}${data.uid}.${ext[ext.length - 1]}`);
      }
    } else if (data.type === 'draftvideo') {
      const ext = data.oldName.split('.');
      const video = document.createElement('video');
      video.preload = 'metadata';
      if (data.oldUid) video.src = `${baseUrlUploaded}${data.oldUid}.${ext[ext.length - 1]}`;
      else video.src = `${baseUrlUploaded}${data.uid}.${ext[ext.length - 1]}`;

      if (data.oldUid) setSrc(`${baseUrlUploaded}${data.oldUid}.${ext[ext.length - 1]}`);
      else setSrc(`${baseUrlUploaded}${data.uid}.${ext[ext.length - 1]}`);

      video.muted = true;
      video.playsInline = true;
      video.currentTime = 1;
      video.play();
    } else if (data.file.type === 'remote') {
      const video = document.createElement('video');

      video.preload = 'metadata';
      video.src = file.location;
      video.muted = true;
      video.playsInline = true;
      video.play();
    } else {
      reader.onload = () => {
        const blob = new Blob([reader.result as string], { type: data.file.type });
        const url = URL.createObjectURL(blob);
        const video: HTMLVideoElement = document.createElement('video');
        const timeupdate = () => {
          if (snapImage(video, url)) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };
        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.className = 'hidden';
        video.src = url;
        video.muted = true;
        video.playsInline = true;
        video.currentTime = 1;

        video.play();
      };

      reader.readAsArrayBuffer(data.file);
      return true;
    }
    // } else {
    //   setSrc(`${baseUrl}thumb_${data.uid}.png`);
    // }
  };
  const snapImage = (video: HTMLVideoElement, url: string): boolean => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    let context = canvas.getContext('2d');
    if (context) context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL();
    const success = image.length > 100000;

    if (data.file.size > maxMediaFileSize) {
      notify(`Media Size is too large for ${data.file.name}. Max size is 40 MB.`, 'error');
      rejectMedia();
      video.pause();
    } else {
      const inputImage = {
        width: canvas.width,
        height: canvas.height,
      };
      const targetVideo = {
        width: resolution[0],
        height: resolution[1],
      };
      const isValid = doesItFit(inputImage, targetVideo, 3);
      checkRatio(data.file.name, isValid, `${resolution[0]}x${resolution[1]}`);
      updateVideoDuration(video.duration);
      let dataRatio = checkRatio(data.file.name, isValid, `${resolution[0]}x${resolution[1]}`);
      setResponse(dataRatio);
    }

    if (success) {
      setSrc(image);
      URL.revokeObjectURL(url);
    }
    return true;
  };
  const handleToggle = (el: IRule, type: IRulesCode) => {
    let rulesC = [...cardRules];
    let found = rulesC.filter(e => {
      return e.code === el.code;
    });
    if (found.length === 0) {
      rulesC.push({
        code: el.code,
        label: el.label,
        type: type,
      });
      setCardRules(rulesC);

      dispatch({
        type: IPublishType.addRulesToMedia,
        payload: {
          rulesC,
          uuid: uuid,
          mediaId: data.uid,
        },
      });
    }
  };
  const removeItem = (ele: IRule, type: IRulesCode) => {
    let rulesC = [...cardRules];

    const el = {
      code: ele.code,
      label: ele.label,
      type: type,
    };

    let found = rulesC.filter(e => {
      return e.code !== el.code;
    });
    setCardRules(found);
    dispatch({
      type: IPublishType.addRulesToMedia,
      payload: {
        rulesC: found,
        uuid: uuid,
        mediaId: data.uid,
      },
    });
  };

  return (
    ((file.action === 'toupload' || file.action === 'isuploaded' || file.type.includes('draft')) && (
      <div
        className={oneLine`w-full bg-white dark:bg-dark-200 p-4 border-b border-bordercolor dark:border-bordercolordark mb-4 rounded relative border-dashed border border-lightGrey rounded-xl`}
      >
        <div className="flex flex-wrap items-start justify-between space-x-7">
          {cardRules.length === 0 ? (
            <>
              <h3 className="flex flex-row flex-wrap mb-4 text-base font-semibold text-dark-default dark:text-white">
                <span className="pr-2 dark:text-white whitespace-nowrap">
                  {index + 1}
                  {'. '}
                  {file.oldName}
                </span>

                <span className="pl-3 ml-3 text-sm font-medium border-l whitespace-nowrap text-lightGrey dark:text-dark-300 border-bordercolor dark:border-bordercolordark">
                  Default Visual
                </span>
              </h3>
            </>
          ) : (
            <>
              <h3 className="flex flex-row flex-wrap mb-4 text-base font-semibold text-dark-default dark:text-white">
                <span className="pr-2 dark:text-white whitespace-nowrap">
                  {index + 1}
                  {'. '}
                  {file.oldName}
                </span>
                <span className="pl-3 ml-3 text-sm font-medium border-l whitespace-nowrap text-lightGrey dark:text-dark-300 border-bordercolor dark:border-bordercolordark">
                  Contextual video ({cardRules.length} {cardRules.length === 1 ? ' rule)' : 'rules)'}
                </span>
              </h3>
            </>
          )}
          <div
            className="flex items-center mb-2 space-x-4 md:space-x-7 chevron-transform"
            style={{ marginLeft: 'auto' }}
          >
            <div
              aria-describedby={uuid}
              onClick={handleClick}
              className={`cursor-pointer whitespace-nowrap bg-lightGrey dark:bg-dark-200 dark:border-2 dark:border-primarydark-default dark:text-primarydark-default 
            bg-opacity-20 h-9 text-center text-lightGrey px-2 md:px-7 flex items-center text-sm font-button font-bold rounded-2xl ${
              open ? 'active' : ''
            }`}
            >
              {intl.formatMessage({
                defaultMessage: 'Add Rule',
                id: 'Label.AddRule',
              })}
              <div className="flex items-center ml-2 chevron text-body dark:text-primarydark-default">
                <ChevronDown set="light" size="small" />
              </div>
            </div>

            <Popover
              id={uuid}
              open={open}
              className="tps-popover dark:dark-shadow"
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            >
              <Rules
                className="bg-grey-500 dark:bg-dark-200"
                cardRules={cardRules}
                rules={rules}
                onClick={(ev: IRule, type) => {
                  handleToggle(ev, type);
                }}
                removeClick={(ev: IRule, type) => {
                  removeItem(ev, type);
                  //handleToggle(ev)
                }}
              />
            </Popover>
            {!isUploading && (
              <div
                onClick={previewMedia}
                className="z-50 flex items-center justify-center rounded-full cursor-pointer bg-lightGrey bg-opacity-20 w-9 h-9 text-body dark:text-white dark:bg-dark-200 dark:border-2 dark:border-primarydark-default "
              >
                <Show data-tip data-for="preview" set="light" size="small" />
                <ReactTooltip
                  id="preview"
                  getContent={() => {
                    return null;
                  }}
                  place="top"
                  className="custom-tooltip"
                >
                  <div className="preview-media-tooltip">Preview</div>
                </ReactTooltip>
              </div>
            )}
            <div
              onClick={rejectMedia}
              className="z-50 flex items-center justify-center mr-4 rounded-full cursor-pointer bg-lightGrey bg-opacity-20 w-9 h-9 text-body dark:text-white dark:bg-dark-200 dark:border-2 dark:border-primarydark-default "
            >
              <Delete data-tip data-for="remove" set="light" size="small" />
              <ReactTooltip
                id="remove"
                getContent={() => {
                  return null;
                }}
                place="top"
                className="custom-tooltip"
              >
                <div className="preview-media-tooltip">Remove</div>
              </ReactTooltip>
            </div>
          </div>
        </div>
        <div className="text-sm dark:text-dark-300">
          <span className="">{isUploading ? uploadingText : 'Completed'}</span>
          <span className="pl-3 ml-3 border-l border-bordercolor dark:border-bordercolordark">
            {isUploading ? percentCompleted + '%' : '100%'}
          </span>
        </div>
        <div className="w-full h-2 mt-3 overflow-hidden rounded bg-lightGrey bg-opacity-40">
          <div
            style={{ width: `${isUploading ? percentCompleted : '100'}` + '%' }}
            className={`h-2 transition-all duration-300 ease-in-out ${
              isUploading ? 'bg-gradient-red' : 'bg-gradient-green'
            }`}
          ></div>
        </div>
      </div>
    )) || <></>
  );
};
export default MediaCard;
