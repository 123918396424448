import { IOptions } from 'interface/ISelect';
import React, { useContext } from 'react';
export interface LocationMapInfoProperty {
  c: number;
  cr: string;
  lrt: string;
  n: string;
  nId: string;
  sId: string;
  t: string;
  tg: string[];
  u: string;
  vt: string;
}

export type MapContextType = {
  totalItems: number;
  searchText: string;
  comingSoon: string;
  onlyBook: string;
  hasImage: string;
  locationType: string[];
  venueType: string[];
  searchTags: IOptions[];
  searchPrices: number[];
  searchCity: string;
  reloadItems: number;
  page: string;
  bbox: any;
  loading: boolean;
  view: string;
  clearFilters: number;
  clearFiltersTrigger: number;
  updateFilters: number;
  selectedLocations: LocationMapInfoProperty[];
  setSelectedLocations: (e: LocationMapInfoProperty[]) => void;

  setSearchTags: (e: IOptions[]) => void;
  setSearchPrices: (e: [number, number]) => void;
  setSearchText: (e: string) => void;
  setSearchCity: (e: string) => void;
  setLocationType: (e: string[]) => void;
  setVenueType: (e: string[]) => void;
  center: [number, number];
  zoom: [number];
  setCenter: (e: [number, number]) => void;
  setZoom: (e: [number]) => void;
  setTotalItems: (e: number) => void;
  setComingSoon: (e: string) => void;
  setOnlyBook: (e: string) => void;
  setHasImage: (e: string) => void;
  setReloadItems: (e: number) => void;
  setLoading: (e: boolean) => void;
  setPage: (e: string) => void;
  setBbox: (e: any) => void;
  setView: (string: any) => void;
  setClearFilters: (e: number) => void;
  setClearFiltersTrigger: (e: number) => void;
  setUpdateFilters: (e: number) => void;
};

export const MapContext = React.createContext<MapContextType>({
  searchText: '',
  searchCity: '',
  totalItems: 0,
  page: '1',
  bbox: [0, 0, 0, 0],
  center: [0, 0],
  zoom: [2],
  locationType: [],
  venueType: [],
  searchTags: [],
  comingSoon: '0',
  onlyBook: '0',
  hasImage: '1',
  reloadItems: 0,
  loading: false,
  view: 'Map',
  clearFilters: 0,
  clearFiltersTrigger: 0,
  updateFilters: 0,

  selectedLocations: [],
  setSelectedLocations: (e: LocationMapInfoProperty[]) => {
    console.log('  ');
  },
  searchPrices: [1, 99999],
  setComingSoon: (e: string) => console.log(''),
  setOnlyBook: (e: string) => console.log(''),
  setHasImage: (e: string) => console.log(''),
  setReloadItems: (e: number) => console.log(''),
  setCenter: (e: [number, number]) => console.log(''),
  setZoom: (e: [number]) => console.log(''),
  setSearchText: (e: string) => console.log(''),
  setSearchCity: (e: string) => console.log(''),
  setLocationType: (e: string[]) => console.log(''),
  setVenueType: (e: string[]) => console.log(''),
  setSearchTags: (e: IOptions[]) => console.log(''),
  setTotalItems: (e: number) => console.log(''),
  setSearchPrices: (e: [number, number]) => console.log(''),
  setLoading: (e: boolean) => console.log(''),
  setPage: (e: string) => console.log(''),
  setBbox: (e: string) => console.log(''),
  setView: (e: string) => console.log(''),
  setClearFilters: (e: number) => console.log(''),
  setClearFiltersTrigger: (e: number) => console.log(''),
  setUpdateFilters: (e: number) => console.log(''),
});

export const useMapContext = () => useContext(MapContext);
