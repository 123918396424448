import { IScheduler } from 'interface/IScheduler';
import { IRadioBoxOptions } from 'interface/ISelect';
import React, { useEffect } from 'react';
import { RadioBox } from '../atoms';
import { PublishContext } from 'context/publish/publish.provider';
import { IPublishType } from 'context/publish/publish.reducer';
import { JWT } from '@api/users';

type IProps = {
  schedule: IScheduler;
};

const SchedulePph = ({ schedule }: IProps) => {
  const [pphOptions, setPphOptios] = React.useState<IRadioBoxOptions[] | null>(null);
  const [locations, setLocations] = React.useState<string[]>([]);
  const { publishState, dispatch } = React.useContext(PublishContext);
  useEffect(() => {
    let options = [];
    let foundFrequncies: any = {};
    let scheduleLocation = [];
    for (const location of schedule.locations) {
      scheduleLocation.push(location.uuid);
      for (const locationFrequency of location.frequencies) {
        if (foundFrequncies[locationFrequency.uuid]) {
          foundFrequncies[locationFrequency.uuid] = foundFrequncies[locationFrequency.uuid] + 1;
        } else {
          foundFrequncies[locationFrequency.uuid] = 1;
        }
      }
    }
    let user = JWT.getJwtUser();

    const maxPph = user.role === 'admin' ? 10000 : 60;

    for (const el of schedule.frequencies) {
      if (3600 / el.seconds <= maxPph)
        options.push({
          value: `${el.uuid}`,
          label: `${3600 / el.seconds}`,
          checked: `${el.seconds}` === `${schedule.pph}`,
          disabled:
            schedule.locations.length !== 1 ? foundFrequncies[el.uuid] !== schedule.locations.length + 1 : false,
        });
    }
    setLocations(locations);
    setPphOptios(options.sort((a, b) => parseInt(a.label) - parseInt(b.label)));
  }, [schedule]);

  const setPPh = (pph: string) => {
    dispatch({
      type: IPublishType.setPph,
      payload: {
        pph,
        locations,
        schedule: schedule.uuid,
      },
    });
  };
  return (
    <div className="w-fit">
      {pphOptions && (
        <RadioBox
          boxSize="big"
          key={`${schedule.uuid}_${pphOptions[0]?.checked}`}
          type="radio"
          name={`primary_${schedule.uuid}`}
          flexClass="flex-1 is-center-box"
          onChange={(e: any) => {
            const l = e.target.id.split('_');
            //  setLocationPph(l[1])
            setPPh(l[1]);
          }}
          options={pphOptions}
        />
      )}
    </div>
  );
};

export default SchedulePph;
