import React from 'react';
import ReactTooltip from 'react-tooltip';
import Pin from '../atoms/Pin';

type IProps = {
  isSelected: boolean;
  type: string;
  hoverBottom?: boolean;
  tooltipText?: string;
};
const MapPin = ({ isSelected = false, hoverBottom, type, tooltipText }: IProps) => {
  return (
    <>
      {(tooltipText && (
        <div
          className={`${
            hoverBottom
              ? 'absolute pin-tooltip-top1 rounded-xl bg-dark-default text-white text-center w-64 p-2 arrow-down left-0'
              : 'absolute pin-tooltip-top rounded-xl bg-dark-default text-white text-center w-64 p-2 arrow-down'
          }`}
          dangerouslySetInnerHTML={{ __html: tooltipText }}
        ></div>
      )) || <></>}
      <Pin isSelected={isSelected} type={type} />
    </>
  );
};

export default MapPin;
