// eslint-disable-next-line import/no-extraneous-dependencies
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  AreaSeries,
  LineMarkSeries,
} from 'react-vis';

import LocationModalType from '../../../../interface/LocationModal';
import React from 'react';

const HourlyAudience = ({ location }: { location: LocationModalType }) => {
  if (!location) {
    return <div />;
  }

  const mode = localStorage.getItem('mode');

  const data = [
    { x: 'MON', y: location.stockData.last7DaysAudience?.[0] || 0 },
    { x: 'TUE', y: location.stockData.last7DaysAudience?.[1] || 0 },
    { x: 'WED', y: location.stockData.last7DaysAudience?.[2] || 0 },
    { x: 'THU', y: location.stockData.last7DaysAudience?.[3] || 0 },
    { x: 'FRI', y: location.stockData.last7DaysAudience?.[4] || 0 },
    { x: 'SAT', y: location.stockData.last7DaysAudience?.[5] || 0 },
    { x: 'SUN', y: location.stockData.last7DaysAudience?.[6] || 0 },
  ];

  //if total audience is 0, return empty div
  if (data.reduce((acc, cur) => acc + cur.y, 0) === 0) {
    return (
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4">Audience Pattern</h3>
        <div className="bg-dark-300/20 px-4 py-2  p-4 rounded-lg mb-6">
          <p className="text-gray-500 dark:text-gray-300">No data available</p>
        </div>
      </div>
    );
  }
  // @ts-ignore
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-4">Audience Pattern</h3>

      <XYPlot
        width={340}
        height={300}
        xType="ordinal"
        margin={{ left: 40, right: 10, top: 10, bottom: 40 }}
        yDomain={[0, Math.max(...data.map(d => d.y))]}
      >
        {/* Grid lines (optional) */}
        <VerticalGridLines style={{ stroke: 'rgba(239,68,68,0.1)' }} />
        <HorizontalGridLines style={{ stroke: 'rgba(239,68,68,0.1)' }} />

        {/* X and Y axes */}
        <XAxis
          style={{
            line: { stroke: 'none' },
            ticks: { stroke: 'none' },
            text: { fill: mode === 'lightMode' ? '#888' : '#fff' },
          }}
        />
        <YAxis
          style={{
            line: { stroke: 'none' },
            ticks: { stroke: 'none' },
            text: { fill: mode === 'lightMode' ? '#888' : '#fff' },
          }}
        />

        {/* Shaded area under the line */}
        <AreaSeries
          // @ts-ignore
          data={data}
          curve="curveMonotoneX"
          color="rgba(239,68,68)"
          opacity={0.2}
          style={{ stroke: 'none' }}
        />

        {/* Line + marker series on top */}
        <LineMarkSeries
          data={data}
          curve="curveMonotoneX"
          color="rgba(239,68,68,1)"
          style={{ strokeWidth: 3 }}
          markStyle={{
            stroke: 'rgba(239,68,68,1)',
            fill: 'rgba(239,68,68,1)',
          }}
        />
      </XYPlot>
    </div>
  );
};
export default HourlyAudience;
