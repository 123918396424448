import React from 'react';
import { useApp } from 'components/app';
import { Icon } from '@iconify/react-with-api';
import Link from 'next/link';

interface AdminLocationButtonsProps {
  location: {
    uuid: string;
    networkId?: string;
  };
  isAdmin: boolean;
}

const AdminLocationButtons: React.FC<AdminLocationButtonsProps> = ({ location, isAdmin }) => {
  const { notify } = useApp(); // Access notify function from AppContext

  // Copy to clipboard function
  const copyToClipboard = (text: string, label: string) => {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          notify(`${label} copied to clipboard!`, 'success');
        })
        .catch(err => {
          console.error(`Failed to copy ${label}:`, err);
          notify(`Failed to copy ${label}`, 'error');
        });
    }
  };

  if (!isAdmin) return null; // Only render if user is admin

  // Common class variables
  const containerClass =
    'flex flex-row items-center justify-between border border-red-500 rounded-lg w-full mb-2 hover:bg-gray-200 dark:hover:bg-gray-700 overflow-hidden';
  const buttonLinkClass =
    'flex items-center whitespace-nowrap justify-center text-xs font-light text-gray-500 dark:text-gray-300 px-2 py-1 hover:bg-gray-300 dark:hover:bg-gray-600 w-full';

  return (
    <div className="w-full space-y-2 mt-4">
      {/* UUID Section (Copy UUID + Admin Location Link) */}
      <div className={containerClass}>
        {/* Copy UUID */}
        <button
          className={`${buttonLinkClass} w-1/2`} // Ensure buttons take 50% width
          onClick={() => copyToClipboard(location.uuid, 'UUID')}
        >
          <Icon icon="mdi:clipboard" className="mr-2" />
          Copy Loc UUID
        </button>

        {/* Open Admin Location Link */}
        <Link href={`https://admin.seeblindspot.com/admin/locations/edit/${location.uuid}`} passHref>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={`${buttonLinkClass} w-1/2`} // Ensure buttons take 50% width
          >
            <Icon icon="mdi:link" className="mr-2" />
            Open Location Admin
          </a>
        </Link>
      </div>

      {/* Network ID Section (Copy Network ID + Admin Network Link) */}
      {location.networkId && (
        <div className={containerClass}>
          {/* Copy Network ID */}
          <button
            className={`${buttonLinkClass} w-1/2`} // Ensure buttons take 50% width
            onClick={() => copyToClipboard(location.networkId!, 'Network ID')}
          >
            <Icon icon="mdi:clipboard" className="mr-2" />
            Copy Network ID
          </button>

          {/* Open Admin Network Link */}
          <Link href={`https://admin.seeblindspot.com/admin/networks/edit/${location.networkId}`} passHref>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={`${buttonLinkClass} w-1/2`} // Ensure buttons take 50% width
            >
              <Icon icon="mdi:link" className="mr-2" />
              Open Network Admin
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default AdminLocationButtons;
