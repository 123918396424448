import { skipOnboardingTour, viewSurveyForm } from '@api/gtm/gtm';
import { JWT, TPSAPI } from '@api/users';
import { useApp } from 'components/app';
import { Buy } from 'react-iconly';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { Button } from './common/atoms';
import { OnboardingTooltip } from './common/atoms/OnboardingTooltip';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import SurveyModal from './common/atoms/SurveyModal';
import useOnboardingContext from 'context/OnboardingContext';
import { ILoginResponse } from 'interface/user/IUserApi';
interface IMainLayoutProps {
  children?: React.ReactNode;
}

export const MainLayout: React.FC<IMainLayoutProps> = (props: IMainLayoutProps) => {
  // const {
  //   state: { isMobileMenuOpen }
  // } = useApp()
  const [themeMode, setThemeMode] = React.useState<string | null>(null);
  const { notify, setReloadJwt, reloadJwt } = useApp();

  useEffect(() => {
    const items = localStorage.getItem('mode');
    if (items) {
      setThemeMode(items);
    }
  }, []);

  const {
    setState,
    state: { run, tourActive, stepIndex, steps },
  } = useOnboardingContext();

  const router = useRouter();

  useEffect(() => {
    // @ts-ignore
    setState({
      run: run,
      stepIndex: stepIndex,
      tourActive: tourActive,
      steps: [
        {
          target: '#sliderTopSeller',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                This is <span className="text-accent-500 font-semibold">The Dashboard</span> where you can find a
                selection of some of the most impactful screens.
              </div>
              <div>
                You can select a few screens for your campaign from here,
                <span className="font-semibold"> but first</span>, let us show you{' '}
                <span className="font-semibold">how to create a campaign</span> and{' '}
                <span className="font-semibold">easily find all the locations</span> .
              </div>
            </div>
          ),
          data: {
            next: '/myCampaigns',
          },
          disableBeacon: true,
        },
        {
          target: '#myCampaigns',
          placement: 'right' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                In <span className="text-accent-500 font-semibold">My Campaigns</span> menu you can find and track{' '}
                <span className="font-semibold">all your campaigns.</span>
              </div>
              <div>
                We've prepared a <span className="font-semibold">DEMO Campaign for you</span>. This will help you get
                familiar with the campaign creation process.
              </div>
            </div>
          ),
          data: {
            next: '/mycampaigns',
            previous: '/',
          },
          disableBeacon: true,
        },
        {
          target: '#firstCampaign',
          placement: 'right' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                This is <span className="text-accent-500 font-semibold">your introductory campaign</span>. Remember,
                it’s just a <strong>DEMO </strong>
                to help you create real campaigns <strong>more quickly.</strong>
              </div>
              <div>
                Here you can <strong>view the status</strong> of your campaign, <strong>delete</strong> it,{' '}
                <strong>duplicate</strong> it, or <strong>access</strong> it.
              </div>
              <div>
                Let's move on to see <strong>how easily</strong> you can set up your campaign.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/basic?cid=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#campaignForm',
          placement: 'right' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                <span className="text-accent-500 font-semibold">Your campaign starts here.</span>
              </div>
              <div>
                Take it easy, just <strong>three more steps</strong> and it'll be ready to Publish.
              </div>
              <div>
                Fill in your campaign <strong>name</strong> and select your <strong>industry</strong>.
              </div>
            </div>
          ),
          data: {
            previous: '/mycampaigns',
            next: '/publish/basic?cid=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#continuePublishBasic',
          placement: 'top' as const,
          content: (
            <>
              <div>
                Once you're all set, hit <span className="text-accent-500 font-semibold">Continue</span> to proceed to
                the <strong>screen selection</strong> step.
              </div>
            </>
          ),
          data: {
            previous: '/',
            next: '/publish/filters?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#publishFilters',
          placement: 'left' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                To make your search <strong>even easier</strong>, you can{' '}
                <span className="text-accent-500 font-semibold">preselect the region and type of screens</span> you’re
                interested in.
              </div>
              <div>No stress—feel free to tweak the filters in the next step if needed.</div>
            </div>
          ),
          data: {
            previous: '/publish/basic?cid=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
            next: '/publish/select?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17&page=1&onlyAvailable=true&hasPictures=false&minPrice=0&maxPrice=3500&locationType=indoor%2Coutdoor%2Cmobile&topLeftLon=-74.30596&topLeftLat=40.91762&bottomRightLon=-73.65332&bottomRightLat=40.47742&view=Half',
          },
          disableBeacon: true,
        },
        {
          target: '#map',
          placement: 'left' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                Here’s <span className="text-accent-500 font-semibold">The Map</span> where you can{' '}
                <strong>browse over 1 million billboards</strong>.
              </div>
              <div>
                Navigate using <strong>drag & drop or zoom</strong>. To get <strong>more info</strong> about a billboard
                or to <strong>book it</strong>, <strong>click on the pin or symbol</strong>.
              </div>
              <div>
                The icons are <strong>color-coded</strong> based on the screen type, and{' '}
                <strong>the spectacular</strong> ones have <strong>unique & customized symbols</strong>.
              </div>
              <div>
                <div></div>
              </div>
            </div>
          ),
          data: {
            previous: '/publish/filters?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
            next: '/publish/select?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17&page=1&onlyAvailable=true&hasPictures=false&minPrice=0&maxPrice=3500&locationType=indoor%2Coutdoor%2Cmobile&topLeftLon=-74.30596&topLeftLat=40.91762&bottomRightLon=-73.65332&bottomRightLat=40.47742&view=Half',
          },
          disableBeacon: true,
        },
        {
          target: '#list',
          placement: 'right' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                In <span className="text-accent-500 font-semibold">The List</span>, you’ll find{' '}
                <strong>the billboards visible on the map</strong>. To see the billboards that{' '}
                <strong>fit your needs</strong>, navigate the map or use the filters.
              </div>
              <div className="flex flex-row items-center gap-2 flex-wrap">
                <span className="whitespace-nowrap">Use the</span>
                <Button buttonSize="sm">Book</Button>
                <span className="whitespace-nowrap">button to add a billboard to your </span>
                <div className="dark:text-dark-400 text-lightGrey flex flex-row items-center gap-2">
                  <Buy set="light" />
                  <strong>Basket</strong>
                </div>
                .
              </div>
              <div>
                <strong>Remember</strong>, the list is sorted by billboard <strong>availability</strong> and{' '}
                <strong>avg. price per hour</strong>.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/select?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17&page=1&onlyAvailable=true&hasPictures=false&minPrice=0&maxPrice=3500&locationType=indoor%2Coutdoor%2Cmobile&topLeftLon=-74.30596&topLeftLat=40.91762&bottomRightLon=-73.65332&bottomRightLat=40.47742&view=Half',
          },
          disableBeacon: true,
        },
        {
          target: '#searchByLocation',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                Use <span className="text-accent-500 font-semibold">The Filters</span> to find <strong>exactly</strong>{' '}
                the screens you need.
              </div>
              <div>
                Here, you can access the main filters and search <strong>by location</strong> (country, city, zipcode,
                street) or <strong>by billboard name</strong>.
              </div>
              <div>
                You can choose to view only certain <strong>types of screens</strong> or switch to{' '}
                <strong>Map View</strong> or <strong>List View</strong>.
              </div>
              <div>
                Expand the <strong>Venue Type</strong> categories and select the category that best fits your campaign
                needs.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/select?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17&page=1&onlyAvailable=true&hasPictures=false&minPrice=0&maxPrice=3500&locationType=indoor%2Coutdoor%2Cmobile&topLeftLon=-74.30596&topLeftLat=40.91762&bottomRightLon=-73.65332&bottomRightLat=40.47742&view=Half',
          },
          disableBeacon: true,
        },
        {
          target: '#moreFilters',
          placement: 'right' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                For an enhanced <strong>search experience</strong>, open this menu to access{' '}
                <span className="text-accent-500 font-semibold">more filters</span>.
              </div>
              <div>
                You can <strong>filter billboards by avg. price per hour</strong>, ensuring you stay within your budget
              </div>
              <div>
                Additionally, you can filter billboards based on their <strong>availability</strong> and choose to
                display only those with <strong>accompanying photos</strong>.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/select?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17&page=1&onlyAvailable=true&hasPictures=false&minPrice=0&maxPrice=3500&locationType=indoor%2Coutdoor%2Cmobile&topLeftLon=-74.30596&topLeftLat=40.91762&bottomRightLon=-73.65332&bottomRightLat=40.47742&view=Half',
          },
          disableBeacon: true,
        },
        {
          target: '#basket',
          placement: 'left' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                In your <span className="text-accent-500 font-semibold">Basket</span>, you'll find a list of{' '}
                <strong>all the billboards you've booked</strong>.
              </div>
              <div>
                You can <strong>remove billboards</strong> from the list or <strong>navigate </strong>to them on the
                map.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/select?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17&page=1&onlyAvailable=true&hasPictures=false&minPrice=0&maxPrice=3500&locationType=indoor%2Coutdoor%2Cmobile&topLeftLon=-74.30596&topLeftLat=40.91762&bottomRightLon=-73.65332&bottomRightLat=40.47742&view=Half',
          },
          disableBeacon: true,
        },
        {
          target: '#footer',
          placement: 'top' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                Once your <strong>billboards selection is ready</strong>, it's time to{' '}
                <span className="text-accent-500 font-semibold">Schedule</span> the days and times your ads will run.
              </div>
              <div>
                Just <strong>2 more steps</strong>, and you're done. We'll handle everything from here.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '.scheduler-main-box',
          placement: 'top' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                <span className="text-accent-500 font-semibold">It’s a breeze!</span> To set your ad for a specific time
                and day, simply <strong>click the slot</strong> where your desired time and day intersect.
              </div>
              <div>
                Selected slots will turn <strong>green</strong> instantly. Keep in mind, slots marked in{' '}
                <strong>red</strong> indicate <strong>unavailability</strong> and cannot be selected.
              </div>
            </div>
          ),
          data: {
            previous:
              '/publish/select?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17&page=1&onlyAvailable=true&hasPictures=false&minPrice=0&maxPrice=3500&locationType=indoor%2Coutdoor%2Cmobile&topLeftLon=-74.30596&topLeftLat=40.91762&bottomRightLon=-73.65332&bottomRightLat=40.47742&view=Half',
            next: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#schedule-list',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                We’ve prepared a <span className="text-accent-500 font-semibold">Global Scheduler</span> that applies{' '}
                <strong>the same schedule to all screens</strong> if selected. To set{' '}
                <strong>a custom schedule for a billboard</strong>, <strong>select one from the list</strong>.
              </div>
              <div>
                <strong>Important:</strong> If you add a custom schedule to a specific screen, it will{' '}
                <strong>no longer be impacted by Global Schedule</strong>.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#schedule-item',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                When <span className="text-accent-500 font-semibold">selecting a billboard</span> for a custom schedule,
                you can view key details:{' '}
                <strong>number of slots (hours) selected, total plays, and the cost for that billboard</strong>.
              </div>
              <div>
                <strong>Pay attention </strong>
                to the <span className="text-accent-500 font-semibold">orange checkmarks</span> in the top right corner,
                as some screens require a <strong>minimum number of plays</strong>.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#schedule-pph',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                <strong>It's important to know</strong> that for some billboards, you can choose{' '}
                <span className="text-accent-500 font-semibold">how many times per hour your ad plays</span>.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#footer',
          placement: 'top' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                Once you've <strong>completed the scheduling process</strong>, there's{' '}
                <span className="text-accent-500 font-semibold">just one more step. </span>
              </div>
              <div>
                Let's see how you can <strong>upload the media</strong> for your campaign.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/media?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#mediaGroup',
          placement: 'right' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                We <span className="text-accent-500 font-semibold">grouped your booked billboards </span>by{' '}
                <strong>type, accepted durations, and resolution</strong>!
              </div>
              <div>
                <strong>Select each group</strong> individually and <strong>add the media</strong>, ensuring you meet
                the group's requirements.
              </div>
            </div>
          ),
          data: {
            previous: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
            next: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#select-duration',
          placement: 'left' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                After selecting the group for which you want to upload media, you'll see here{' '}
                <span className="text-accent-500 font-semibold">the billboards included in that group</span>.
              </div>
              <div>
                For certain groups, you can choose from multiple <strong>durations</strong> for the uploaded media.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#drop-container',
          placement: 'left' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                In this container, <span className="text-accent-500 font-semibold">you can add media</span> either by{' '}
                <strong>drag and drop</strong>, <strong>browsing</strong> your files, or selecting from{' '}
                <strong>the media library</strong>.
              </div>
              <div>
                You can also review the <strong>upload requirements</strong> for your media
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/schedule?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#uploaded-media',
          placement: 'left' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                Check here to see if your media <strong>has been uploaded successfully</strong>.
              </div>
              <div>
                Add <strong>rules</strong> to your media or <strong>preview it</strong>.
              </div>
              <div>
                If everything looks good, <strong>click on Continue</strong> and let's{' '}
                <span className="text-accent-500 font-semibold">publish your campaign</span>.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/review?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#review-table',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                <span className="text-accent-500 font-semibold">Everything is ready now.</span> Take a final look at
                your campaign <strong>to ensure everything is ok.</strong>
              </div>
              <div>
                In this table, you can see <strong>all the details for each screen.</strong>
              </div>
            </div>
          ),
          data: {
            previous: '/publish/media?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
            next: '/publish/review?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#review-download',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                To get an <strong>overview of your campaign</strong>, you can{' '}
                <span className="text-accent-500 font-semibold"> download or access the media plan page </span> before
                publishing.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/review?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#review-summary',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                Before publishing, review the final{' '}
                <span className="text-accent-500 font-semibold">campaign budget</span> details.
              </div>
              <div>
                <strong>Remember</strong>, you can use <strong>Blindspot Credits or Vouchers</strong>.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/review?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
        {
          target: '#review-publish',
          placement: 'bottom' as const,
          content: (
            <div className="flex flex-col gap-2">
              <div>
                Click the <span className="text-accent-500 font-semibold">Publish Button</span> and you're all set.
              </div>
              <div>
                Check the <strong>My Campaigns</strong> page to see <strong>all the details and the status</strong> of
                your campaign.
              </div>
              <div>
                Our tour ends here. We hope it was helpful and that you'll find creating{' '}
                <strong>amazing campaigns much easier.</strong>
              </div>
              <div>
                If you want to redo this tour, you can reactivate it from your <strong>profile settings</strong>.
              </div>
            </div>
          ),
          data: {
            previous: '/',
            next: '/publish/review?campaignId=1b5a9446-1e0e-498c-8bed-ee7e31e55b17',
          },
          disableBeacon: true,
        },
      ],
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setState({ run: false, stepIndex: 0, steps, tourActive: false });
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCallback = async (data: CallBackProps) => {
    const { action, index, step: { data: { next = null, previous = null } = {} } = {}, type } = data;

    if (action === 'skip') {
      router.push('/');
      skipOnboardingTour(stepIndex + 1);
      setState({ run: false, stepIndex: 0, steps, tourActive: false });
      const response = await TPSAPI.updateOnboarding({
        sw: true,
      });

      if (response.hasError === true) {
        setState({ run: true, stepIndex: 0, steps, tourActive: true });
      } else {
        if (response.token) {
          JWT.setJwt(response.token as unknown as ILoginResponse);
          setReloadJwt(reloadJwt + 1);
        }
      }
    }

    const isPreviousAction = action === 'prev';
    if (type === 'step:after') {
      if (isPreviousAction && index === 1) {
        setState({ run: true, stepIndex: 0, steps, tourActive });
        return;
      }

      if (isPreviousAction && index === 2) {
        router.push(previous);
        setState({ run: true, stepIndex: 1, steps, tourActive });
        return;
      }

      if (index === 0) {
        setState({ run: true, stepIndex: 1, steps, tourActive });
        return;
      }

      if (index === 3) {
        if (isPreviousAction) {
          router.push(previous);
          setState({ run: true, stepIndex: 2, steps, tourActive });
          return;
        }

        setState({ run: true, stepIndex: 4, steps, tourActive });
        return;
      }

      if (index === 4 && isPreviousAction) {
        setState({ run: true, stepIndex: 3, steps, tourActive });
        return;
      }

      if (index === 5 && isPreviousAction) {
        router.push(previous);
        setState({ run: true, stepIndex: 4, steps, tourActive });
        return;
      }

      if (index === 6) {
        if (isPreviousAction) {
          router.push(previous);
          setState({ run: true, stepIndex: 5, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 7, steps, tourActive });
        return;
      }
      if (index === 7) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 6, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 8, steps, tourActive });
        return;
      }
      if (index === 8) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 7, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 9, steps, tourActive });
        return;
      }
      if (index === 9) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 8, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 10, steps, tourActive });
        return;
      }
      if (index === 10) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 9, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 11, steps, tourActive });
        return;
      }

      if (index === 11 && isPreviousAction) {
        setState({ run: true, stepIndex: 10, steps, tourActive });
        return;
      }

      if (index === 12) {
        if (isPreviousAction) {
          router.push(previous);
          setState({ run: true, stepIndex: 9, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 13, steps, tourActive });
        return;
      }

      if (index === 13) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 12, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 14, steps, tourActive });
        return;
      }

      if (index === 14) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 13, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 15, steps, tourActive });
        return;
      }
      if (index === 15) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 14, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 16, steps, tourActive });
        return;
      }

      if (index === 16 && isPreviousAction) {
        setState({ run: true, stepIndex: 15, steps, tourActive });
        return;
      }

      if (index === 17) {
        if (isPreviousAction) {
          router.push(previous);
          setState({ run: true, stepIndex: 16, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 18, steps, tourActive });
        return;
      }
      if (index === 18) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 17, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 19, steps, tourActive });
        return;
      }
      if (index === 19) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 18, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 20, steps, tourActive });
        return;
      }

      if (index === 21) {
        if (isPreviousAction) {
          router.push(previous);
          setState({ run: true, stepIndex: 20, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 22, steps, tourActive });
        return;
      }
      if (index === 22) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 21, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 23, steps, tourActive });
        return;
      }
      if (index === 23) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 22, steps, tourActive });
          return;
        }
        setState({ run: true, stepIndex: 24, steps, tourActive });
        return;
      }

      if (index < 24) {
        if (isPreviousAction) {
          setState({ run: true, stepIndex: 23, steps, tourActive });
          return;
        }
        setState({ run: true, steps, stepIndex, tourActive });
        router.push(isPreviousAction && previous ? previous : next);
      }

      if (index === 24) {
        if (isPreviousAction) {
          setState({ run: true, steps, stepIndex: 23, tourActive });
        } else {
          router.push('/');
          setState({ run: false, stepIndex: 0, tourActive: false, steps });
          const response = await TPSAPI.updateOnboarding({
            sw: true,
          });

          if (response.hasError === true) {
            setState({ run: true, stepIndex: 0, steps, tourActive: true });
          } else {
            if (response.token) {
              JWT.setJwt(response.token as unknown as ILoginResponse);
              setReloadJwt(reloadJwt + 1);
            }
          }
        }
      }
    }
  };

  const [isExternalPage, setIsExternalPage] = React.useState(false);

  useEffect(() => {
    if (router.pathname.includes('/map-website')) {
      setIsExternalPage(true);
    }
  }, [router.asPath]);
  const {
    state: { isMobileMenuOpen },
  } = useApp();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const showSurvey = () => {
    let user = JWT.getJwtUser();
    if (
      user.extra &&
      user.emailValidation === true &&
      (Object.keys(user.extra).length === 0 || user.extra.hasSurvey === false)
    ) {
      viewSurveyForm();
      setTimeout(() => {
        if (!isExternalPage) {
          setState({ run: false, stepIndex: 0, steps, tourActive: false });
          setOpenModal(true);
        }
      }, 4000);
    } else {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    showSurvey();
  }, [router.pathname]);

  const onSubmitSurvey = () => {
    setOpenModal(false);
  };

  return (
    <div className={`${isExternalPage ? 'h-full' : `mainLayout ${tourActive ? 'overflow-y-hidden' : ''}`}`}>
      {openModal && <SurveyModal onSubmit={onSubmitSurvey} />}
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        scrollDuration={20}
        disableScrolling={stepIndex === 19 || stepIndex === 20 || stepIndex === 24 || stepIndex === 4 ? false : true}
        tooltipComponent={OnboardingTooltip}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: themeMode === 'darkMode' ? '#1a1a1a' : '#fff',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          },
        }}
      />
      {props.children}
    </div>
  );
};

export default MainLayout;
