import ReactMapboxGl, { Marker, RotationControl, ZoomControl } from 'react-mapbox-gl';
import MapPin from 'components/common/molecules/MapPin';
import React, { useEffect, useState } from 'react';

const MAPBOX_TOKEN = 'pk.eyJ1IjoidHBzZW5nYWdlIiwiYSI6ImNqZGluMGcyazEybGcycW4wdnhwanR3cXcifQ.XpvUi6PLguDLfYrksmcDxw';
const Mapbox = ReactMapboxGl({ accessToken: MAPBOX_TOKEN });
const styles = {
  map: {
    width: '100%',
    marginLeft: '0',
    height: '100%',
  },
  marker: {
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const SmallMap = ({ lng, lat, name }: { lng: number; lat: number; name: string }) => {
  const mapRef = React.useRef<any>(null);
  const [themeMode, setThemeMode] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const items = localStorage.getItem('mode');
    if (items) {
      setThemeMode(items);
    }
  }, []);

  return (
    <div className="h-[200px] w-full rounded-3xl overflow-hidden">
      <Mapbox
        style={
          themeMode === 'darkMode'
            ? 'mapbox://styles/tpsengage/cl4xtn4nr000914nvbn65ipuv'
            : 'mapbox://styles/tpsengage/ckxynelz3es3d15qzp2vx7a33'
        }
        movingMethod="easeTo"
        center={[lng, lat]}
        zoom={[15]}
        containerStyle={styles.map}
        onStyleLoad={map => {
          mapRef.current = map;
        }}
      >
        <Marker style={styles.marker} coordinates={[lng, lat]}>
          <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <MapPin type="" hoverBottom isSelected={true} tooltipText={isHovered ? `<strong>${name}</strong>` : ''} />
          </div>
        </Marker>
      </Mapbox>
    </div>
  );
};

export default SmallMap;
