import React from 'react';
import { Icon } from '@iconify/react-with-api';
import { oneLine } from 'common-tags';
import ReactTooltip from 'react-tooltip';
import { Lock } from 'react-iconly';
import AnimatedStatus from 'components/common/atoms/AnimatedStatus';
type Color = 'primary' | 'accent' | 'warn' | 'danger' | 'success' | 'neutral';
type LabelColor = 'white' | 'dark';

const getColor = (color: Color | undefined) => {
  switch (color) {
    case 'primary':
      return 'checked:bg-white hover:bg-white checked:border-primary-500 hover:border-primary-500 text-primary-500 focus:ring-0 focus:ring-offset-0';
    case 'accent':
      return 'checked:bg-white hover:bg-white checked:border-accent-500  hover:border-accent-500 text-accent-500  focus:ring-0 focus:ring-offset-0';
    case 'warn':
      return 'checked:bg-white hover:bg-white checked:border-warn-500  hover:border-warn-500 text-warn-500 focus:ring-0 focus:ring-offset-0';
    case 'danger':
      return 'checked:bg-white hover:bg-white checked:border-accdangerent-500  hover:border-danger-500 text-danger-500 focus:ring-0 focus:ring-offset-0';
    case 'success':
      return 'checked:bg-white hover:bg-white checked:border-success-500  hover:border-success-500 text-success-500  focus:ring-0 focus:ring-offset-0';
    case 'neutral':
      return 'checked:bg-white hover:bg-white checked:border-neutral-500  hover:border-neutral-500 text-neutral-500  focus:ring-0 focus:ring-offset-0';
    default:
      return 'checked:bg-white hover:bg-white checked:border-primary-500  hover:border-primary-500 text-primary-500  focus:ring-0 focus:ring-offset-0';
  }
};

export interface IRadioProps extends React.HTMLProps<HTMLInputElement> {
  innerRef?: any;
  label?: string;
  disabled?: boolean;
  hint?: string;
  color?: Color;
  name: string;
  labelColor: LabelColor;
  isLabelBold?: boolean;
  validation?: any;
  multiple?: boolean;
  value: any;
  checked?: boolean;
  justText?: boolean;
  defaultBg?: string;
  hasError?: boolean;
  hasErrorContainer?: boolean;
  tooltipText?: any;
  hasGlobalConnection?: boolean;
  indexSchedule?: number;
}
const Radio: React.FC<IRadioProps> = ({
  innerRef,
  label,
  disabled,
  validation,
  hint,
  name,
  labelColor = 'white',
  isLabelBold,
  color,
  multiple,
  value,
  checked,
  justText,
  defaultBg,
  hasError,
  hasErrorContainer = false,
  tooltipText,
  hasGlobalConnection = true,
  indexSchedule,
  ...otherProps
}: IRadioProps) => {
  const [innerError, setInnerError] = React.useState('');
  const { error } = validation;
  React.useEffect(() => {
    if (error) {
      setInnerError(error.message);
    } else {
      setTimeout(() => {
        setInnerError('');
      }, 300);
    }
  }, [error]);

  const inputErrorClasses = oneLine`
  text-danger-500
`;
  const inputClasses = oneLine`
  inline
  ${innerError ? inputErrorClasses : ''}
  ${disabled ? 'bg-white dark:bg-dark-200' : ''}
`;

  const [isTooltipVisible, setTooltipVisibility] = React.useState(false);

  React.useEffect(() => {
    setTooltipVisibility(true);
  }, []);

  return (
    <div className="classic-radio relative z-10">
      <label className={`${inputClasses} inline-flex items-center w-full`}>
        <input
          ref={innerRef}
          disabled={disabled}
          data-cy={`schedItem${indexSchedule}`}
          className={`
              ease-in-out transition duration-300
              animated rounded-full
              cursor-pointer
              border
              border-grey-200
              w-5
              h-5
              ${getColor(color)}
              ${justText ? 'hidden radio just-text' : ''}
              ${disabled ? 'bg-gray-300' : ''}
            `}
          type="radio"
          name={name}
          color={color}
          value={value}
          defaultChecked={checked}
          {...otherProps}
        />
        {label && (
          <h6
            className={oneLine`text-left w-full min-w-[186px] text-center text-base md:text-sm font-medium ml-1 cursor-pointer ${
              labelColor === 'white'
                ? 'text-white dark:text-dark-100'
                : 'text-dark-500 dark:text-dark-400 dark:bg-dark-200'
            } ${
              justText
                ? 'transition-all duration-300 ease-in-out border border-bordercolor dark:border-bordercolordark dark:border-opacity-20 rounded-2xl px-5 py-2'
                : ''
            } ${defaultBg ? defaultBg : ''}`}
          >
            {!hasGlobalConnection ? (
              <div className="flex flex-row flex-nowrap w-full justify-center">
                <Icon
                  data-tip
                  data-for="lock"
                  className="inline icon-vertical-fix"
                  height="1rem"
                  width="1rem"
                  icon="icomoon-free:unlocked"
                />
                <h6 className="ml-2">{label}</h6>
              </div>
            ) : (
              label
            )}
          </h6>
        )}
        {hint && (
          <div className="flex text-dark-default dark:text-dark-300 mt-1">
            <div className="w-4 h-4" />
            <p className="ml-2 text-xs text-gray-500">{hint}</p>
          </div>
        )}
      </label>
      {value !== '1' && hasErrorContainer && (
        <>
          <AnimatedStatus hasError={hasError} label={label} />

          {(isTooltipVisible && (
            <ReactTooltip id={label} data-cy={`schedItem${indexSchedule}Info`} place="top" className="custom-tooltip">
              {tooltipText && (
                <div className="preview-media-tooltip">
                  <div className="mb-1">{tooltipText?.row1}</div>
                  <div className="mb-1">{tooltipText?.row2}</div>
                  <div>{tooltipText?.row3}</div>
                </div>
              )}
            </ReactTooltip>
          )) ||
            null}
          {(isTooltipVisible && (
            <ReactTooltip id="lock" place="top" className="custom-tooltip">
              <div className="preview-media-tooltip">
                <div className="mb-1">
                  <p>This location is disconnected from the Global Schedule</p>
                  <p>and will no longer be impacted by the changes from there</p>
                </div>
              </div>
            </ReactTooltip>
          )) ||
            null}
        </>
      )}

      {error && (
        <div className={`text-danger-500 w-fit text-xs pt-2 space-x-1 collapsible-error ${error ? 'expanded' : ''}`}>
          <Icon
            className="inline icon-vertical-fix"
            height="1rem"
            width="1rem"
            icon="ant-design:exclamation-circle-outlined"
          />
          <p className="inline">{innerError}</p>
        </div>
      )}
    </div>
  );
};

Radio.defaultProps = {
  disabled: false,
  validation: {},
  readOnly: false,
};
export default Radio;
