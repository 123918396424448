/* eslint-disable import/no-anonymous-default-export */
import { getErrorMessage } from '@utils/errorHandling';
import API from 'api/base';
import { NextPageContext } from 'next';
import { enGB } from 'date-fns/locale';

import {
  ICampaignReturn,
  ICampaignList,
  IRulesApi,
  IRulesApiReturn,
  ICampaignData,
  ICampaignBudget,
  IPerformance,
} from 'interface/ICampaign';
import { DateTime } from 'luxon';
import { ILocation, ILocationFull, IMapPins } from 'interface/ILocation';
import { IMediaUpload } from 'interface/IMedia';
import { ISchedulerApiSubmit } from 'interface/IScheduler';
import { IReviewPrice } from 'interface/IPrice';
import { format, addDays, startOfDay, addHours, getUnixTime } from 'date-fns';
import { ILocationReach } from 'interface/ILocationReach';
import InventoryAPI from '@api/base-inventory';
import { CancelToken } from 'axios';

type getCampaignDataResponse = {
  campaign?: ICampaignData;
  status: boolean;
  errorMessage: string;
};

export type getCampaignBudget = {
  budget?: ICampaignBudget;
  status: boolean;
  errorMessage: string;
};

type getCampaignPerformance = {
  performance?: IPerformance[];
  status: boolean;
  errorMessage: string;
};

const getPerformanceGroupReach = async (
  uuid: string,
  tz: string,
  filterType: string,
  screen: string,
  ctx?: NextPageContext
): Promise<getCampaignPerformance> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `campaigns/performance-group/getdata/${uuid}/reach`,
        data: {
          tz: tz,
          filterType,
          screen: screen,
        },
      },
      ctx
    );
    const performance: IPerformance[] = result.data.data;
    return {
      status: true,
      performance,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
const getPerformanceReach = async (
  uuid: string,
  tz: string,
  filterType: string,
  screen: string,
  ctx?: NextPageContext
): Promise<getCampaignPerformance> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `campaigns/performance/getdata/${uuid}/reach`,
        data: {
          tz: tz,
          filterType,
          screen: screen,
        },
      },
      ctx
    );
    const performance: IPerformance[] = result.data.data;
    return {
      status: true,
      performance,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getPerformanceGroup = async (
  uuid: string,
  tz: string,
  filterType: string,
  screen: string,
  ctx?: NextPageContext
): Promise<getCampaignPerformance> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `campaigns/performance-group/getdata/${uuid}/plays`,
        data: {
          tz: tz,
          filterType,
          screen: screen,
        },
      },
      ctx
    );
    const performance: IPerformance[] = result.data.data;
    return {
      status: true,
      performance,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getPerformance = async (
  uuid: string,
  tz: string,
  filterType: string,
  screen: string,
  ctx?: NextPageContext
): Promise<getCampaignPerformance> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `campaigns/performance/getdata/${uuid}/plays`,
        data: {
          tz: tz,
          filterType,
          screen: screen,
        },
      },
      ctx
    );
    const performance: IPerformance[] = result.data.data;
    return {
      status: true,
      performance,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

type CampaignTags = {
  status: boolean;
  errorMessage: string;
  tags?: string[];
};
const getTags = async (ctx?: NextPageContext): Promise<CampaignTags> => {
  try {
    const d: string[] = [
      'Roadside',
      'StreetHub',
      'Residential',
      'D48',
      'Digital Billboard',
      'Retail',
      'Bar/Restaurant/Jukebox',
      'Commercial',
      'The Greens & Views',
      'Shopping Mall',
      'D48M',
      'MSA D48',
      'MotorwayServiceStation',
      'Bus Shelters',
      'Subway Station',
      'Dubai Marina',
      'The Palm',
      'Business Bay',
      'DM6',
      'Grocery Store',
      'JBR',
      'Abu Dhabi',
      'JLT',
      'Crypto friendly',
      'Spectacular',
      'D96',
      'DIFC Gate District',
      'DIFC Large Format',
      'Airport',
      'Executive Network',
      'FAME',
      'Digital Bulletin',
      'Media City',
      'Dubai Creek',
      'DT & DIFC Res',
      'Dubai Silicon Oasis',
      'Airport Lounge',
      'DIFC Free Zone',
      'Convenience Store ',
      'Cinema Lobby ',
      'Gas station',
      'The Gate',
      'Doctors Office Waiting Room ',
      'Gym',
      'Superbet',
    ];

    return {
      status: true,
      tags: d,
      errorMessage: '',
    };
    const result = await API(
      {
        method: 'get',
        url: `screens/get/getTags`,
      },
      ctx
    );
    const tags: string[] = result.data.data;
    return {
      status: true,
      tags,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getCampaigGroupData = async (uuid: string, ctx: NextPageContext): Promise<getCampaignDataResponse> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `campaigns/get-by-group/${uuid}`,
      },
      ctx
    );

    const campaign: ICampaignData = result.data.data.campaign;
    return {
      status: true,
      campaign,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getCampaignData = async (uuid: string, ctx: NextPageContext): Promise<getCampaignDataResponse> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `campaigns/get/${uuid}`,
      },
      ctx
    );
    const campaign: ICampaignData = result.data.data.campaign;
    return {
      status: true,
      campaign,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getSpendByGroup = async (uuid: string, ctx?: NextPageContext): Promise<number> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `campaigns/get-spend-group/${uuid}`,
      },
      ctx
    );
    const campaign: number = result.data.data.spend;
    return campaign;
  } catch (error) {
    return -1;
  }
};

const getSpend = async (uuid: string, ctx?: NextPageContext): Promise<number> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `campaigns/get-spend/${uuid}`,
      },
      ctx
    );
    const campaign: number = result.data.data.spend;
    return campaign;
  } catch (error) {
    return -1;
  }
};

const getSharableMap = async (uuid: string): Promise<getCampaignDataResponse> => {
  try {
    const result = await API({
      method: 'get',
      url: `campaigns/get/sharedmap/${uuid}`,
    });
    const campaign: ICampaignData = result.data;
    return {
      status: true,
      campaign,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
const getMediaPlanData = async (uuid: string): Promise<getCampaignDataResponse> => {
  try {
    const result = await API({
      method: 'get',
      url: `campaigns/mediaplan/${uuid}`,
    });
    const campaign: ICampaignData = result.data;
    return {
      status: true,
      campaign,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
const getGroupMediaPlanData = async (uuid: string): Promise<getCampaignDataResponse> => {
  try {
    const result = await API({
      method: 'get',
      url: `campaigns/mediaplan-group/${uuid}`,
    });
    const campaign: ICampaignData = result.data;
    return {
      status: true,
      campaign,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getGroupBudget = async (uuid: string, ctx?: NextPageContext): Promise<getCampaignBudget> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `financial/getGroupBudget/${uuid}`,
      },
      ctx
    );
    const budget: ICampaignBudget = result.data.data;
    return {
      status: true,
      budget,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
const getBudget = async (uuid: string, ctx?: NextPageContext): Promise<getCampaignBudget> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `financial/data/${uuid}`,
      },
      ctx
    );
    const budget: ICampaignBudget = result.data.data;
    return {
      status: true,
      budget,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getOwnCampaigns = async (
  ctx: NextPageContext,
  params?: { q: string; filters: string[]; page: number; pageSize: number }
): Promise<ICampaignReturn> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `campaigns/get-own?v=v5`,
        params: params
          ? {
              name: params.q,
              status: params.filters.join(','),
              page: params.page,
              pageSize: params.pageSize,
            }
          : null,
      },
      ctx
    );
    const campaigns: ICampaignList[] = result.data.data.campaigns;
    const total = result.data.data.pagination.totalRecords;
    return {
      status: true,
      campaigns,
      total,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
      total: 0,
    };
  }
};

const getBulkCampaigns = async (
  ctx: NextPageContext,
  params?: { q: string; filters: string[]; page: number; pageSize: number }
): Promise<ICampaignReturn> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `/campaigns/monday/mycampaigns`,
        params: params
          ? {
              name: params.q,
              status: params.filters,
              page: params.page,
              pageSize: params.pageSize,
            }
          : null,
      },
      ctx
    );
    const campaigns: ICampaignList[] = result.data.data;
    const total = Number(result.data.totalRecords);
    return {
      status: true,
      campaigns,
      total,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
      total: 0,
    };
  }
};

type ILocationAnalytics = {
  errorMessage: '';
  status: boolean;
  analytics: {
    MONDAY: number[];
    TUESDAY: number[];
    WEDNESDAY: number[];
    THURSDAY: number[];
    FRIDAY: number[];
    SATURDAY: number[];
    SUNDAY: number[];
  };
};
const getLocationAnalytics = async (uuid: string, ctx?: NextPageContext): Promise<ILocationAnalytics> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `screens/analytics/weekly-detailed`,
        data: {
          location: uuid,
        },
      },
      ctx
    );

    return {
      status: true,
      analytics: response.data.data,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      analytics: {
        MONDAY: [],
        TUESDAY: [],
        WEDNESDAY: [],
        THURSDAY: [],
        FRIDAY: [],
        SATURDAY: [],
        SUNDAY: [],
      },
      errorMessage: getErrorMessage(error),
    };
  }
};

const getLocations = async (lat: number, lng: number, ctx?: NextPageContext): Promise<any> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `screens/get/all?v=v5`,
        data: {
          getDistance: 20,
          lat,
          lng,
        },
      },
      ctx
    );

    const locations = response.data.data;
    return {
      status: true,
      locations,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

type ILocationList = {
  status: boolean;
  locations?: ILocation[];
  errorMessage: string;
};

type ILocationListFull = {
  status: boolean;
  locations?: ILocationFull[];
  errorMessage: string;
};

type IMapPinsList = {
  status: boolean;
  locations?: ILocationFull[];
  errorMessage: string;
};

const getCountLocation = async (type: string, value: string): Promise<any> => {
  try {
    const response = await InventoryAPI({
      method: 'get',
      url: `locations/${type === 'city' ? 'cities' : 'countries'}/count?${type}=${value}`,
    });

    const count = response.data.count;
    return {
      status: true,
      count,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getNetworks = async (ctx?: NextPageContext): Promise<any> => {
  try {
    const response = await InventoryAPI(
      {
        method: 'get',
        url: `networks/list`,
      },
      ctx
    );

    const networks = response.data;
    return {
      status: true,
      networks,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getLocationsCluster2 = async (locationList: any, ctx?: NextPageContext): Promise<IMapPinsList> => {
  try {
    const response = await InventoryAPI(
      {
        method: 'post',
        url: `locations/list`,
        data: {
          locationList: locationList,
        },
        // cancelToken: signal
      },
      ctx
    );

    const locations = response.data;
    return {
      status: true,
      locations,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getLocationList = async (params: any, ctx?: NextPageContext): Promise<IMapPinsList> => {
  try {
    const response = await InventoryAPI(
      {
        method: 'get',
        url: `locations/list?${params}`,
      },
      ctx
    );
    const locations = response.data;
    return {
      status: true,
      locations,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      // @ts-ignore
      locations: { data: [] },
      errorMessage: getErrorMessage(error),
    };
  }
};

const getLocationsCluster = async (
  lat: any,
  lng: any,
  searchText: any,
  ctx?: NextPageContext
): Promise<IMapPinsList> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `screens/get/clusters`,
        data: {
          lat,
          lng,
          searchText,
        },
        // cancelToken: signal
      },
      ctx
    );

    const locations = response.data.data.locations;
    return {
      status: true,
      locations,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getLocationsAll = async (ctx?: NextPageContext): Promise<ILocationList> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `screens/get/allnew?v=v5`,
      },
      ctx
    );

    const locations = response.data.data;
    return {
      status: true,
      locations,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
type ILocationItem = {
  status: boolean;
  locations?: ILocationFull;
  errorMessage: string;
};

const getLocationById = async (id: string, ctx?: NextPageContext): Promise<ILocationItem> => {
  try {
    const response = await API(
      {
        method: 'get',
        url: `screens/getscreen/${id}`,
      },
      ctx
    );
    const locations: ILocationFull = response.data.data.location[0];
    const newLocationsFreq = locations.frequencies.sort((a, b) => {
      return parseInt(`${a.seconds}`, 10) - parseInt(`${b.seconds}`, 10);
    });
    locations.frequencies = newLocationsFreq;
    return {
      status: true,
      locations,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
const getLocationByIds = async (id: string[], ctx?: NextPageContext): Promise<ILocationListFull> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `screens/getListById`,
        data: {
          id,
        },
      },
      ctx
    );
    const locations: ILocationFull[] = response.data.data.locations;

    return {
      status: true,
      locations,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const calculatePrice = async (
  schedule: ISchedulerApiSubmit[],
  cancelToken?: CancelToken,
  ctx?: NextPageContext
): Promise<string> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `financial/get-total-price/v5`,
        data: {
          schedule: schedule,
        },
      },
      ctx,
      cancelToken
    );
    // const locations: ILocationFull = response.data.data.location[0]
    return response.data.data.total;
  } catch (error) {
    return '0';
  }
};

const calculateReach = async (
  schedule: ISchedulerApiSubmit[],
  cancelToken?: CancelToken,
  ctx?: NextPageContext
): Promise<ILocationReach[] | void> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `analytics/estimate`,
        data: {
          schedule,
        },
      },
      ctx,
      cancelToken
    );
    const locationReach: ILocationReach[] = response.data.data;

    return locationReach;
  } catch (error) {
    console.log('error', error);
  }
};
const calculatePriceEndPrice = async (
  schedule: ISchedulerApiSubmit[],
  ctx?: NextPageContext
): Promise<IReviewPrice | void> => {
  try {
    const response = await API(
      {
        method: 'post',
        url: `financial/get-final-price/v5`,
        data: {
          schedule: schedule,
        },
      },
      ctx
    );
    // const locations: ILocationFull = response.data.data.location[0]
    return response.data.data;
  } catch (error) {}
};

interface IUploadMediaWithSize extends IMediaUpload {
  width: number;
  height: number;
}

const removeMedia = async (id: string, ctx?: NextPageContext): Promise<{ status: boolean; errorMessage: string }> => {
  try {
    const response = await API(
      {
        method: 'delete',
        url: `media/delete/${id}`,
      },
      ctx,
      {
        timeout: 180000,
      }
    );
    return {
      status: response.data.status,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const uploadMedia = async (
  data: IUploadMediaWithSize,
  onUploadProgress: any,
  ctx?: NextPageContext
): Promise<{
  status: number;
  errorMessage: string;
}> => {
  try {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('uid', data.uid);
    formData.append('duration', `${data.duration || 0}`);
    formData.append('type', data.type);
    formData.append('oldType', data.oldType);
    formData.append('width', `${data.width}`);
    formData.append('height', `${data.height}`);

    const response = await API(
      {
        method: 'post',
        url: `media/uploadmedia`,
        data: formData,
        onUploadProgress,
      },
      ctx,
      {
        timeout: 180000,
      }
    );
    return {
      status: response.data.status,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: 0,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getRules = async (ctx?: NextPageContext): Promise<IRulesApiReturn> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `info/rules`,
      },
      ctx
    );
    const rules: IRulesApi[] = result.data.data;
    return {
      status: true,
      rules,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const downloadCsv = async (id: string, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        responseType: 'blob',
        method: 'get',
        url: `/campaigns/download/media-plan-csv/${id}`,
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const downloadPdf = async (id: string, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        responseType: 'blob',
        method: 'get',
        url: `/campaigns/download/media-plan-pdf/${id}`,
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getCsv = async (submissionData: any, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: '/campaigns/create/media-plan-csv',
        data: submissionData,
        // responseType: 'blob',
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getPdf = async (submissionData: any, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: '/campaigns/create/media-plan',
        data: submissionData,
        responseType: 'blob',
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getInvoice = async (submissionData: any, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `/invoice/get/${submissionData}`,
        data: submissionData,
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
const toggleImpression = async (campaignUuid: string, status: boolean, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `/campaigns/toggle-impression`,
        data: {
          campaignUuid,
          status,
        },
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
const toggleNetwork = async (campaignUuid: string, status: boolean, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `/campaigns/toggle-network`,
        data: {
          campaignUuid,
          status,
        },
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const toggleSov = async (campaignUuid: string, status: boolean, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: `/campaigns/toggle-sov`,
        data: {
          campaignUuid,
          status,
        },
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};
const getMediaPlan = async (submissionData: any, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `/campaigns/get/mediaplan/${submissionData}`,
        data: submissionData,
        responseType: 'blob',
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const saveCampaignDraft = async (data: any, ctx?: NextPageContext) => {
  try {
    // const data = getDraftState();
    delete data.message;
    if (data.name?.length > 2) {
      try {
        const result = await API(
          {
            method: 'post',
            url: '/campaigns/save-campaign-draft-v5',
            data: data,
          },
          ctx
        );

        return result.data;
      } catch (error) {
        return {
          status: false,
          errorMessage: getErrorMessage(error),
        };
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const saveCampaign = async (submissionData: any, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: '/campaigns/create?v=v5',
        data: submissionData,
      },
      ctx,
      {
        timeout: 120000,
      }
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const saveMediaPlan = async (submissionData: any, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: '/campaigns/create/save-mediaplan',
        data: submissionData,
      },
      ctx,
      {
        timeout: 120000,
      }
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const trackMediaPlan = async (data: any, ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: '/media-plans/track',
        data: data,
      },
      ctx,
      {
        timeout: 120000,
      }
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getStats = async (ids: string[], ctx?: NextPageContext): Promise<any> => {
  try {
    const result = await API(
      {
        method: 'post',
        url: '/analytics/campaign-stats',
        data: { campaigns: ids },
      },
      ctx
    );

    return result.data;
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
    };
  }
};

const getUserDraft = async (
  ctx?: NextPageContext,
  params?: { q: string; filters: string[]; page: number; pageSize: number }
): Promise<ICampaignReturn> => {
  try {
    const result = await API(
      {
        method: 'get',
        url: `campaigns/get-all-drafts-v5`,
        params: params
          ? {
              name: params.q,
              status: params.filters,
              page: params.page,
              pageSize: params.pageSize,
            }
          : {},
      },
      ctx
    );
    let campaigns: ICampaignList[];
    let total: number = 0;
    if (params) {
      campaigns = result.data.data.drafts.map((el: any) => {
        return {
          thumbId: 'draft-160133_960_720.png',
          uuid: el.campaignId,
          name: el.metadata.name,
          locations: el.metadata.locations,
          created: el.createdAt ? DateTime.fromISO(el.createdAt).toMillis() : 1,
          plays: 0,
          reach: 0,
          status: 'draft',
        };
      });
      total = result.data.data.pagination.totalRecords;
    } else {
      campaigns = result.data.data.map((el: any) => {
        return {
          thumbId: 'draft-160133_960_720.png',
          uuid: el.campaignId,
          name: el.metadata.name,
          locations: el.metadata.locations,
          created: el.createdAt ? DateTime.fromISO(el.createdAt).toMillis() : 1,
          plays: 0,
          reach: 0,
          status: 'draft',
        };
      });
    }

    return {
      status: true,
      campaigns,
      total: total ? total : 0,
      errorMessage: '',
    };
  } catch (error) {
    return {
      status: false,
      errorMessage: getErrorMessage(error),
      total: 0,
    };
  }
};

const getCampaignByDraftId = async (draftId: string): Promise<any> => {
  const result = await API({
    method: 'get',
    url: `campaigns/create-from-draft-v5/${draftId}`,
  });
  return result.data.data;
};

const getDraftById = async (draftId: string): Promise<any> => {
  const result = await API({
    method: 'get',
    url: `campaigns/get-draft-v5/${draftId}`,
  });
  return result.data?.data?.metadata;
};

export default {
  getOwnCampaigns,
  toggleSov,
  getBulkCampaigns,
  getUserDraft,
  getLocations,
  getLocationsAll,
  getLocationById,
  getLocationAnalytics,
  getRules,
  calculatePrice,
  uploadMedia,
  calculatePriceEndPrice,
  calculateReach,
  downloadPdf,
  downloadCsv,
  getCsv,
  getPdf,
  saveCampaign,
  getMediaPlan,
  getInvoice,
  getCampaignData,
  getMediaPlanData,
  getBudget,
  getPerformance,
  getLocationList,
  getLocationsCluster,
  getPerformanceGroup,
  getLocationsCluster2,
  getSpend,
  getStats,
  getPerformanceReach,
  getTags,
  getLocationByIds,
  getGroupBudget,
  saveCampaignDraft,
  saveMediaPlan,
  trackMediaPlan,
  getDraftById,
  toggleNetwork,
  getCampaignByDraftId,
  removeMedia,
  toggleImpression,
  getNetworks,
  getCountLocation,
  getGroupMediaPlanData,
  getSharableMap,
  getCampaigGroupData,
  getPerformanceGroupReach,
  getSpendByGroup,
};
