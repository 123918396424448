import { useUsermaven } from '@usermaven/nextjs';

import { useEffect } from 'react';
import { JWT } from '@api/users';
import { useRouter } from 'next/router';

const UsermavenAnalytics = () => {
  const router = useRouter();
  const { id } = useUsermaven();

  useEffect(() => {
    try {
      const user = JWT.getJwtUser();

      if (user && user.id !== '') {
        id({
          // Required attributes
          id: JWT.getJwtUser().id, // Unique ID for the user in database.
          email: JWT.getJwtUser()?.email,
          name: `${JWT.getJwtUser().firstName} ${JWT.getJwtUser().lastName}`,
          first_name: JWT.getJwtUser().firstName,
          last_name: JWT.getJwtUser().lastName,
          company: {
            id: JWT.getJwtUser().id,
          },
        });
      }
    } catch (error) {
      console.error('Error initializing Usermaven:', error);
    }
  }, [router.events]);

  // This component doesn't render anything visible
  return null;
};

export default UsermavenAnalytics;
