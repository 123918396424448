export const convertSeconds = (seconds: number) => {
  // Make sure the input is a valid number
  if (typeof seconds !== 'number' || isNaN(seconds) || seconds < 0) {
    return 'Please provide a valid non-negative number of seconds';
  }

  // Constants for conversion
  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
  const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;

  // Calculate days, hours, minutes, and remaining seconds
  const days = Math.floor(seconds / SECONDS_IN_DAY);
  const remainingAfterDays = seconds % SECONDS_IN_DAY;

  const hours = Math.floor(remainingAfterDays / SECONDS_IN_HOUR);
  const remainingAfterHours = remainingAfterDays % SECONDS_IN_HOUR;

  const minutes = Math.floor(remainingAfterHours / SECONDS_IN_MINUTE);
  const remainingSeconds = remainingAfterHours % SECONDS_IN_MINUTE;

  // Create result object
  const result = {
    days,
    hours,
    minutes,
    seconds: remainingSeconds,
  };

  // Construct a formatted string representation
  let formattedResult = '';

  if (days > 0) {
    formattedResult += `${days} d`;
  }

  if (hours > 0) {
    formattedResult += formattedResult ? ', ' : '';
    formattedResult += `${hours} h`;
  }

  if (minutes > 0) {
    formattedResult += formattedResult ? ', ' : '';
    formattedResult += `${minutes} min`;
  }

  if (remainingSeconds > 0 || formattedResult === '') {
    formattedResult += formattedResult ? ' and ' : '';
    formattedResult += `${remainingSeconds} s`;
  }

  // Return both the object and formatted string
  return formattedResult;
};
